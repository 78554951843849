import Vue from "vue";
import Vuex from "vuex";
import VueAxios from "vue-axios";
import VueAuthenticate from "vue-authenticate";
import axios from "axios";
import router from "@/router";
import Jsona from 'jsona';

Vue.use(Vuex);
Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

// Import and initialize all required libraries
const vueAuth = VueAuthenticate.factory(Vue.prototype.$http, {
  baseUrl: process.env.VUE_APP_API_BASE_URL,
  tokenName: "access_token",
  loginUrl: "/login",
  registerUrl: "/register",
  headers: {
    'Accept'      : "application/vnd.api+json",
    'Content-Type': "application/vnd.api+json",
  },
});

export default {
  state: {
    isAuthenticated: localStorage.getItem("vue-authenticate.vueauth_access_token") !== null,
    namespaced: true
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    }
  },

  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
    }
  },

  actions: {
    login(context, payload) {
     return vueAuth.login(payload.user, payload.requestOptions)
        .then(response => {
          context.commit("isAuthenticated", {
            isAuthenticated: vueAuth.isAuthenticated()
          });
          return {
            list: jsona.deserialize(response.data),
            meta: response.data.meta
          };
        });
    },

    register(context, payload) {
      // Normal user registration
      if (!payload.preventRegisterRedirection) {
        vueAuth.register(payload.user, payload.requestOptions)
        .then(response => {
          context.commit("isAuthenticated", {
            isAuthenticated: vueAuth.isAuthenticated()
          });
          // redirect to dashboard
          router.push({path: "/"});
        }).catch(err => {
          return err?.response?.data
        });
      }
      else {
        // Fast user registration
        return vueAuth
          .register(payload.user, payload.requestOptions)
          .then(response => {
            context.commit("isAuthenticated", {
              isAuthenticated: vueAuth.isAuthenticated()
            });
            return response;
          })
          .catch(err => {
            return err?.response?.data
          });
      }
    },

    logout(context, payload) {
      vueAuth.logout().then(response => {
          context.commit("isAuthenticated", {
            isAuthenticated: vueAuth.isAuthenticated()
          });
          // redirect to dashboard
        router.push({name: "Login"});
      });
    }
  }
};

<template>
  <div class="row align-content-center">
    <div class="col-md-10 offset-md-1 mt-5">
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col-md-9 order-md-0 order-last">
            <p class="mb-0">{{$t("scanEventPage.qrCodeScanner")}}</p>
          </div>
        </div>

        <qr-code-scanner
          :qrbox="250"
          :fps="10"
          style="width: auto; border: 0 !important;"
          @result="onScan"
        />
      </card>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import QrCodeScanner from "@/components/QrCodeScanner.vue";
export default {
  layout: "ScanEventPage",
  components: {
    QrCodeScanner,
  },
  data() {
    return {
      popupOpened: false,
    };
  },
  methods: {
    async onScan(decodedText) {
      if (!this.popupOpened && this.validURL(decodedText)) {
        this.popupOpened = true;
        const confirmation = await swal.fire({
          title: "$t('scanEventPage.ticketLinkScanned')",
          html:
           $t('scanEventPage.clickOnThisLinkToVisitTheEvent\'sTicket') + '<br><hr>' +
            '<br><b>' + $t('scanEventPage.ticketLink') + ':</b><br><a href="' +
            decodedText +
            '" target="_blank">' +
              decodedText +
              ' <br><hr>',
          type: "success",
          confirmButtonClass: "btn btn-outline-danger btn-fill",
          confirmButtonText: "Close",
          buttonsStyling: false,
          icon: "success",
        });
        if (confirmation.isDismissed || confirmation.value) {
          this.popupOpened = false;
        }
      }
    },
    validURL(str) {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },
  },
};
</script>

<style>
#qr-code-full-region__dashboard_section #qr-code-full-region__dashboard_section_csr .html5-qrcode-element {
  color: #fff;
  background: #5e72e4;
  border-color: #5e72e4;
  border-radius: .75rem;
  margin: 15px;
  padding: 10px;
}


</style>

<template>
  <div class="row">
    <!--Event settings-->
    <div class="col-md-6">
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col-md-12 order-md-0 order-first">
            <p class="mb-0">{{ $t("editEvents.eventSpecificSetting") }}</p>
          </div>
        </div>
        <div class="card-body mt-0 ml--2">
          <base-input :label="$t('editEvents.showOnCalendar')">
            <base-switch
              class="mr-0"
              v-model="item.show_on_calendar"
              :description="{
                show: true,
                content: $t('editEvents.displayThisEventInPublicMyCalendar'),
              }"
              type="danger"
            />
          </base-input>
        </div>

        <div class="card-body mt--5 ml--2">
          <base-input :label="$t('editEvents.showOnCollection')">
            <base-switch
              class="mr-0"
              v-model="item.show_on_collection"
              :description="{
                show: true,
                content: $t('editEvents.displayThisEventInPublicMyCollections'),
              }"
              type="danger"
            />
          </base-input>
        </div>

        <div class="card-body mt--5 ml--2">
          <base-input
            :label="$t('editEvents.showAnnouncementOnEventPublicPage')"
          >
            <base-switch
              class="mr-0"
              v-model="item.show_latest_announcement"
              :description="{
                show: false,
                content: $t(
                  'editEvents.displayLatestAnnouncementOnEventPublicPage'
                ),
              }"
              type="danger"
            />
          </base-input>
        </div>
      </card>
    </div>

    <!--Google Analytics settings-->
    <div class="col-md-6 mb-0">
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col-md-12 order-md-0 order-first">
            <p class="mb-0">Event-specific customization</p>
          </div>
        </div>

        <base-premium-overlay :enabled="isFreeUser()">
          <div class="card-body mt--5 ml--2">
            <base-input label="Customize Ticket Booking Button">
              <base-switch
                class="mr-0"
                v-model="item.custom_book_button_label"
                :description="{
                  show: false,
                  content:
                    'Rename the ( \'Book Me In Scotty\' ) to a custom label and create a more personalized Call-To-Action ticketing Button that reinforce your Brand and Improve the selling experience.',
                }"
                type="danger"
              />
            </base-input>

            <div v-if="item.custom_book_button_label">
              <base-input
                label="Book Button Label"
                v-model="item.book_button_label"
                placeholder="Add Book Button label"
              />

              <base-input
                label="Book Promotion Label"
                v-model="item.book_promotion_label"
                placeholder="Add Promotion label"
              />

              <base-input label="Book Button Color">
                <color-picker v-model="color" style="width: 100%" />
              </base-input>

              <slot name="label">
                <span class="text-uppercase text-muted text-sm-center"
                  >Custom Button Preview</span
                >
              </slot>
              <card id="cta" class="mt-2" type="frame">
                <div class="d-flex justify-content-between">
                  <h6 class="surtitle text-muted mt-3">
                    <i class="fas fa-ticket" />
                    <span v-if="item.book_promotion_label">
                      {{ item.book_promotion_label }}
                    </span>

                    <span v-else>Book your ticket with</span>
                  </h6>
                  <div>
                    <base-button
                      id="base-cta-ticket-booking"
                      data-testid="base-cta-ticket-booking"
                      type="danger"
                      data-toggle="tooltip"
                      class="mx-auto"
                      style="cursor: default"
                      :style="{
                        background: item.book_button_color,
                        'border-color': item.book_button_color,
                      }"
                      block
                    >
                      <span v-if="item.book_button_label">
                        {{ item.book_button_label }}
                      </span>
                      <span v-else>
                        <img
                          src="/img/brand/bmis-default-logo-cta.png"
                          style="
                            max-width: 1.3rem;
                            max-height: 1.2rem;
                            padding-right: 10px;
                          "
                        />
                      </span>
                    </base-button>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </base-premium-overlay>
      </card>

      <card>
        <div slot="header" class="row align-items-center">
          <div class="col-md-12 order-md-0 order-last">
            <p class="mb-0">{{ $t("editEvents.eventSpecificInsights") }}</p>
          </div>
        </div>

        <div class="card-body mt--2 ml--2">
          <base-input
            :label="$t('editEvents.googleAnalyticsTrackingCode')"
            v-model="item.google_analytics_id"
            placeholder="GA4 Tracking ID ( G-E903XXXX )"
          />

          <div>
            <base-alert type="secondary">
              <el-timeline class="ml--5 mt-2 mb--3">
                <el-timeline-item
                  icon="fa fa-info-circle"
                  :color="color.base"
                  size="large"
                >
                  <small class="text-muted">
                    {{ $t("editEvents.howToSetupAnalyticsForGA4") }}
                    <a
                      href="https://support.google.com/analytics/answer/9304153?hl=en"
                      target="_blank"
                    >
                      [ {{ $t("editEvents.officialTutorial") }} ]
                    </a>
                  </small>
                </el-timeline-item>
              </el-timeline>
            </base-alert>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import {
  Select,
  Option,
  Tabs,
  TabPane,
  Collapse,
  CollapseItem,
  Timeline,
  TimelineItem,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
} from "element-ui";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import formMixin from "@/mixins/form-mixin";
import BasePremiumOverlay from "@/components/Premium/BasePremiumOverlay.vue";
import ValidationError from "@/components/ValidationError.vue";
import { Slider } from "vue-color";

export default {
  name: "edit-event-actions",
  mixins: [formMixin],
  components: {
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    BaseInput,
    BasePremiumOverlay,
    "color-picker": Slider,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      color: {
        hex: " ",
        base: "#5E72E4FF",
      },
    };
  },
  watch: {
    color: {
      handler: "updateTagColor",
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.initCustomColor();
  },
  methods: {
    updateTagColor() {
      if (this.color.hex !== " ") {
        this.item.book_button_color = this.color.hex.toString();
      }
    },
    initCustomColor() {
      if (this.item?.book_button_color) {
        this.color = this.item?.book_button_color?.toString();
      }
    },
  },
};
</script>

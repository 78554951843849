import Vue from "vue";
import axios from "axios";

// Import jQuery globally
window.$ = window.jQuery = require('jquery');

// Import Vue App
import App from "@/App.vue";

// Demo mode
import isDemoPlugin from "@/plugins/is-demo.js";

// Generic plugins
import DashboardPlugin from "@/plugins/dashboard-plugin";

// Vue packages
import VueAxios from "vue-axios";
import VueMeta from 'vue-meta';

// Generic custom styles setup
import "./assets/css/custom.css";

// Vuex - for State Management for router&store
import router from "@/router";
import store from "@/store";

// Import language libary
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);
function loadLocaleMessages() {
  const loadedLocales = {};
  const locales = require.context(
    './assets/translations', // Path to translations folder
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
    locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      loadedLocales[locale] = locales(key);
    }
    });
  return loadedLocales;
}
const i18n = new VueI18n({
  locale: 'en', // default locale
  messages: loadLocaleMessages()
});



// App plugins setup
Vue.use(DashboardPlugin);
Vue.use(VueAxios, axios);
Vue.use(isDemoPlugin);
Vue.use(VueMeta, { keyName: 'head' });

// Sentry for RTL error tracking
import * as Sentry from '@sentry/vue';

Sentry.init({
  Vue: Vue,
  dsn: "https://02bb81fd0d33459a852196703a91a44b@o341256.ingest.sentry.io/1881173",
  environment: process.env.VUE_APP_SENTRY_LOGS_ENV,
  logErrors: true,
  // Track your app's child components to get more details about the rendering process
  trackComponents: true,
  timeout: 1000,
  // This sets the sample rate to be 10%. You may want this to be 100% in production
  replaysSessionSampleRate: process.env.VUE_APP_SENTRY_REPLAYS_SAMPLE,
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  tracesSampleRate: 1.0,
  // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  autoSessionTracking: process.env.VUE_APP_SENTRY_LOGS_ENABLE,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "localhost",
        "localhost:8080",
        "app.bookmeinscotty.com",
        "app.bookmeinscotty.com.au",
        "dev.bookmeinscotty.com",
        "staging.bookmeinscotty.com",
        /^\//
      ],
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
    new Sentry.Feedback({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "light",
      isEmailRequired: true,
      isNameRequired: true,
      buttonLabel: '',
      showBranding:false,
      // formTitle: 'Report an Issue or Bug'
      formTitle: 'Need to speak to someone?',
      submitButtonLabel: 'Submit',
      messagePlaceholder: 'We’re happy to make your event experience smooth and enjoyable! Feel free to let us know whether it’s about registration, event details, or technical support.. '

    }),
  ],
});

// Redirect to /login on response error (4xx, 5xx)
if(process.env.VUE_APP_ENV !== 'local') {
  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    // Remove token and redirect user to login in case of CORS error
    if (typeof error.response === "undefined") {
      localStorage.removeItem("vue-authenticate.vueauth_access_token");
      window.location = "/login";
    }
    return Promise.reject(error);
  });
}

// Google Analytics - GTag Analytics
import VueGtag from 'vue-gtag';

Vue.use(VueGtag, {}, router);


/*********************************************************************************************************************
 * WARNING
 * App initialization for Vue JS v2.x (it shall be modified or used with caution)
 *********************************************************************************************************************/
/* eslint-disable no-new */
const app = new Vue({
  router: router,
  store: store,
  el: "#app",
   i18n,
  render: h => h(App)
});

// Load app in the store
store.$app = app;

/*********************************************************************************************************************
 * WARNING - Global Vue v2.x Mixins Functions (aka Helpers)
 * It affects all the Vue components across the entire application, so it shall be modified or used with caution
*********************************************************************************************************************/
import "@/mixins/global-mixin";

/*********************************************************************************************************************
 * WARNING - Global Vue v2.x Mixins Functions (aka Helpers)
 * It affects all the Vue components across the entire application, so it shall be modified or used with caution
 *********************************************************************************************************************/
import "@/mixins/timezone-mixin";

/*********************************************************************************************************************
 * WARNING - Global Vue v2.x Filters
 * It affects all the Vue components across the entire application, so it shall be modified or used with caution
 *********************************************************************************************************************/
import "@/util/filters"

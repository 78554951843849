<template>
  <div class="card-body">
    <form @submit.prevent="handleSubmit">
      <base-input :label="$t('addTag.name') + '*'" v-model="tag.name" />
      <validation-error :errors="apiValidationErrors.name" />
      <base-input :label="$t('addTag.color')">
        <color-picker v-model="color" style="width: 100%" />
      </base-input>
      <validation-error :errors="apiValidationErrors.color" />

      <div class="my-4">
        <base-button
          type="button"
          class="btn btn-sm btn-primary"
          native-type="submit"
          :disabled="!tag.name"
        >
          <i class="fa fa-save" /> {{ $t("addTag.createTag") }}
        </base-button>
      </div>
    </form>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Slider } from "vue-color";
export default {
  name: "add-tag",
  mixins: [formMixin],
  components: {
    ValidationError,
    "color-picker": Slider,
  },
  data() {
    return {
      tag: {
        type: "tags",
        name: null,
        color: null,
      },
      color: {
        hex: "#A1FF00",
      },
    };
  },
  watch: {
    color: {
      handler: "updateTagColor",
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$store
          .dispatch("tags/add", this.tag)
          .then(() => (this.tag = this.$store.getters["tags/tag"]));

        this.$notify({
          type: "success",
          message: this.$t("addTag.tagAddedSuccessfully") + ".",
        });

        this.$emit("tagAdded", this.tag);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("general.oopsSomethingWentWrong") + "!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    updateTagColor() {
      this.tag.color = this.color.hex;
    },
  },
};
</script>

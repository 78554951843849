<template>
  <div class="container-fluid mt-5">
    <!--    <user-promo-plan :role="me.roles[0].name"></user-promo-plan>-->

    <el-tabs class="mt-2" v-model="activeName">
      <el-tab-pane name="details">
        <span slot="label">
          <i class="fa fa-address-card mr-1" /> {{$t("userProfilePage.myDetails")}}
        </span>
        <div class="row">
          <div class="col-md-6 order-md-0 order-last">
            <div>
              <user-edit-card :user="user" />
            </div>

            <div class="mt-5">
              <user-password-card :user="user" />
            </div>
          </div>

          <div class="col-md-6 order-md-1 order-first">
            <user-meta-data
              :user="user"
              :requiredFieldsMissing="requiredFieldsMissing"
            />
          </div>
        </div>
        <!-- <div class="col-md-6 order-md-1 order-last"></div> -->
      </el-tab-pane>

      <el-tab-pane name="preferences">
        <span slot="label">
          <i class="fas fa-user-edit mr-1" /> {{$t("userProfilePage.myPreferences")}}
        </span>
        <div class="row">
          <div class="col-md-6">
            <user-preferences-card :user="user" />
          </div>
          <div class="col-md-6">
            <!-- Service Integration -->
            <div class="card" v-if="isDeveloper()">

              <div class="card-header">
                <h1>{{$t("userProfilePage.serviceIntegration")}}</h1>
              </div>
              <div class="card-body">
                <form
                    ref="integrations_meta"
                    @submit.prevent="updateUserIntegration"
                >
                  <base-input :label="$t('userProfilePage.syncWithAdditionalBusinessCalendar')">
                    <base-switch
                        class="mr-1"
                        v-model="user.google_calendar_synch"
                    ></base-switch>
                  </base-input>

                  <base-input
                      :label="$t('userProfilePage.pleaseProvideValidGoogleEmail')"
                      prepend-icon="fas fa-envelope"
                      placeholder="Google email"
                      v-model="user.google_email"
                      v-if="user?.google_calendar_synch"
                  />

                  <div
                      v-if="
                      user?.google_email && !checkGoogleEmail(user.google_email)
                    "
                      class="custom-error-field"
                  >
                    {{$t('userProfilePage.theFieldMustContainValidGoogleEmail')}}
                  </div>

                  <base-input :label="$t('userProfilePage.emailNotifications')">
                    <base-switch
                        class="mr-1"
                        v-model="user.email_notifications"
                        :disabled="true"
                    ></base-switch>
                  </base-input>

                  <base-input
                      type="token"
                      :label="$t('userPreferencesCard.paymentIntegration')"
                      name="snipcart_token"
                      autocomplete="on"
                      class="mb-3"
                      prepend-icon="fa fa-shapes"
                      :placeholder="$t('userPreferencesCard.provideSnipcartPaymentTokenToAcceptDirectPayment')"
                      v-model="user.snipcart_token"
                      :disabled="!isDeveloper()"
                  />

                  <div class="my-4">
                    <base-button
                        type="button"
                        class="btn btn-sm btn-primary"
                        native-type="submit"
                        :disabled="
                        user.google_calendar_synch &&
                        !checkGoogleEmail(user.google_email)
                      "
                    >
                      {{$t("userProfilePage.updateService")}}
                    </base-button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane name="payments">
        <span slot="label">
          <i class="fas fa-money-bill-wave mr-1" />{{$t("userProfilePage.myPayments")}}
        </span>
        <div>
          <user-invoice-infos
            v-if="isPremiumUser() || isProfessionalUser() || isDeveloper()"
            @invoiceFormValid="invoiceFormValid($event)"
            :user="user"
          />
          <div v-else>
            <div class="card-body">
              <base-premium-overlay :enabled="isFreeUser()">
                <div class="text-center">
                  <img
                    src="/img/assets/bmis-payment-gateways-placeholders.png"
                    style="max-width: 100%"
                  />
                </div>
              </base-premium-overlay>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import UserInvoiceInfos from "@/views/Users/Profile/UserInvoiceCard.vue";
import UserEditCard from "@/views/Users/Profile/UserEditCard.vue";
import UserPasswordCard from "@/views/Users/Profile/UserPasswordCard.vue";
import UserPreferencesCard from "@/views/Users/Profile/UserPreferencesCard.vue";
import UserMetaData from "@/views/Users/Profile/UserMetaData.vue";
import { Tabs, TabPane } from "element-ui";
import BasePremiumOverlay from "@/components/Premium/BasePremiumOverlay.vue";
import ValidationError from "@/components/ValidationError.vue";
// import UserPromoPlan from "@/views/Promo/UserUpgrade/UserPromoPlan.vue";

export default {
  name: "UserProfile",
  layout: "DashboardLayout",
  components: {
    ValidationError,
    BasePremiumOverlay,
    UserEditCard,
    UserPasswordCard,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    UserMetaData,
    UserPreferencesCard,
    UserInvoiceInfos,
  },

  data() {
    return {
      user: {
        type: "profile",
        id: null,
        name: null,
        email: null,
        profile_image: null,
        timezone: null,
        google_calendar_synch: null,
      },
      me: {},
      activeName: "details",
      requiredFieldsMissing: [],
    };
  },
  created() {
    this.getProfile();
  },
  methods: {
    async getProfile() {
      this.me = this.$store.getters["profile/me"];
      this.user = { ...this.me };
    },
    invoiceFormValid(missingFields) {
      this.requiredFieldsMissing = missingFields;
    },
    async updateUserIntegration() {
      if (!this.user.google_calendar_synch) {
        delete this.user.google_email;
      } else if (
        this.user.google_calendar_synch &&
        this.checkGoogleEmail(this.user.email)
      ) {
        this.user.google_email = this.user.email;
      }
      try {
        await this.$store.dispatch("users/update", this.user);
        this.$refs["integrations_meta"].reset();

        this.resetApiValidation();

        this.$notify({
          type: "success",
          message: this.$t('userProfilePage.userProfileUpdatedSuccessfully'),
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong') + '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    checkGoogleEmail(email) {
      const googleEmailRegex =
        /^[a-z0-9]((\.|\+)?[a-z0-9]){5,}@g(oogle)?mail\.com$/g;
      return googleEmailRegex.test(email);
    },
  },
};
</script>

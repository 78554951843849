import { render, staticRenderFns } from "./EditEventResources.vue?vue&type=template&id=14f0c33c"
import script from "./EditEventResources.vue?vue&type=script&lang=js"
export * from "./EditEventResources.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
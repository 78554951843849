<template>
  <div class="container-fluid">
    <div
      id="speaker-details"
      class="row justify-content-center mt-4"
      style="padding-bottom: 10px !important"
    >
      <div class="col-md-10 order-md-0 order-1">
        <div class="card">
          <!-- Card body -->
          <div class="card-body d-flex align-items-center">
            <div
              v-if="item.profile_image"
              class="avatar avatar-xxl rounded-circle"
              data-toggle="tooltip"
              data-original-title="Alexander Smith"
            >
              <img alt="Image placeholder" :src="item.profile_image" />
            </div>
            <div class="ml-3">
              <h2 class="text-left text-base">
                {{ item.first_name }} {{ item.last_name }}
              </h2>
              <h4>{{ item.expertise }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Speaker Details -->
    <div
      id="speaker-details"
      class="row justify-content-center mt-4"
      style="padding-bottom: 10px !important"
    >
      <!-- Happening details' WHEN-WHERE -->
      <div class="col-md-6 order-md-0 order-0">
        <!-- About this speaker section-->
        <div class="card">
          <!-- Card body -->
          <div class="card-body">
            <h3 class="inline col-md-10 text-base">
              <i class="fa fa-clipboard-list mr-2" />{{$t("baseSpeakerPage.aboutThisSpeaker")}}
            </h3>
            <h4 class="mb--2">{{ item.biography }}</h4>
            <hr />

            <div class="mt-5">
              <h3 class="inline col-md-10 text-base" style="margin-top: 1em">
                <i class="fa fa-tags mr-2" />{{$t("baseSpeakerPage.availableFor")}}
              </h3>
              <div class="badge badge-default badge-pill mr-3">
                <span v-if="item.physical"
                  ><i class="fas fa-check"></i> {{$t("baseSpeakerPage.physical")}}</span
                >
              </div>
              <div class="badge badge-default badge-pill mr-3">
                <span v-if="item.virtual"
                  ><i class="fas fa-check"></i> {{$t("baseSpeakerPage.virtual")}}</span
                >
              </div>
              <div class="badge badge-default badge-pill mr-3">
                <span v-if="item.podcast"
                  ><i class="fas fa-check"></i> {{$t("baseSpeakerPage.podcast")}}</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <!-- Card body -->
          <div class="card-body">
            <!-- Speaker Share -->
            <h3 class="col-md-10 text-left text-base" style="display: inline">
              <i class="fas fa-share mr-2" />{{$t("baseSpeakerPage.pressMediaLinks")}}:

              <!-- Card body -->
              <div class="row align-content-around mb--5">
                <div class="col-md-12 mb-1">
                  <div v-for="(contact, index) in item.contacts" :key="index">
                    {{ contact.type }}:
                    <div class="badge badge-default badge-pill mr-3">
                      {{ contact.value }}
                    </div>

                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="$t('baseSpeakerPage.sendAnEmailToTheSpeaker')"
                      placement="bottom"
                    >
                      <a
                        v-if="
                          contact.type.toLowerCase() === 'email' &&
                          contact.value
                        "
                        :href="
                          'mailto:' +
                          contact.value +
                          '?subject=' +
                          '[Speaker] ' +
                          item.first_name +
                          item.last_name +
                          ' (by Book Me In Scotty)' +
                          '&body=' +
                          '%0D%0A' +
                          'Hey,' +
                          '%0D%0A' +
                          'I found your email from here: ' +
                          speakerPublicURL +
                          '%0D%0A' +
                          '%0D%0A' +
                          'Speaker Tags: ' +
                          getHashtagsForEmail()
                        "
                      >
                        <base-button round class="btn btn-icon-only bg-danger">
                          <span class="btn-inner--icon">
                            <i class="fa fa-envelope" aria-hidden="true" />
                          </span>
                        </base-button>
                      </a>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </h3>
          </div>
        </div>
      </div>
      <!-- Social share and Happening section-->
      <div class="col-md-4 order-md-1 order-1">
        <div class="card">
          <!-- Card body -->
          <div class="card-body">
            <!-- Speaker Share -->
            <h3
              class="col-md-10 text-left text-base"
              style="display: inline"
              v-if="tags"
            >
              <i class="fas fa-share mr-2" />{{$t("baseSpeakerPage.shareThisSpeakerOn")}}
              <!-- Card body -->
              <div class="row align-content-around mb--5">
                <div class="col-md-12 mb-1">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('baseSpeakerPage.copyThisSpeakersPublicLink')"
                    placement="bottom"
                  >
                    <base-button
                      round
                      @click="copyURL()"
                      class="btn-base btn-icon-only pb-1 pt-1 mt-2 mb-2"
                    >
                      <span class="btn-inner--icon"
                        ><i class="fa fa-link"
                      /></span>
                    </base-button>
                  </el-tooltip>

                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('baseSpeakerPage.shareThisSpeaqkerViaEmail')"
                    placement="bottom"
                  >
                    <a
                      :href="
                        'mailto:' +
                        '' +
                        '?subject=' +
                        '[Speaker] ' +
                        item.first_name +
                        item.last_name +
                        ' (by Book Me In Scotty)' +
                        '&body=' +
                        '%0D%0A' +
                        'Hey,' +
                        '%0D%0A' +
                        'I found this amazing speaker - visit here for more: ' +
                        speakerPublicURL +
                        '%0D%0A' +
                        '%0D%0A' +
                        'Short description:' +
                        '%0D%0A' +
                        speakerShortDescription +
                        '%0D%0A' +
                        '%0D%0A' +
                        'Speaker Tags: ' +
                        getHashtagsForEmail()
                      "
                    >
                      <base-button round class="btn btn-icon-only bg-danger">
                        <span class="btn-inner--icon">
                          <i class="fa fa-envelope" aria-hidden="true" />
                        </span>
                      </base-button>
                    </a>
                  </el-tooltip>
                  <share-network
                    network="facebook"
                    :url="speakerPublicURL"
                    :title="item.first_name + ' ' + item.last_name"
                    :description="item.description"
                    image="https://www.uniconexed.org/wp-content/uploads/canstockphoto21211673-online-event-770x662.jpg"
                    :quote="item.first_name + ' ' + item.last_name"
                    :hashtags="getHashtags()"
                    class="mr-2"
                  >
                    <base-button
                      type="facebook"
                      class="btn-icon-only rounded-circle"
                    >
                      <span class="btn-inner--icon"
                        ><i class="fab fa-facebook"></i
                      ></span>
                    </base-button>
                  </share-network>

                  <share-network
                    network="twitter"
                    :url="speakerPublicURL"
                    :title="item.first_name + ' ' + item.last_name"
                    :description="item.description"
                    :quote="item.first_name + ' ' + item.last_name"
                    :hashtags="getHashtags()"
                    class="mr-2"
                  >
                    <base-button
                      type="twitter"
                      class="btn-icon-only rounded-circle"
                    >
                      <span class="btn-inner--icon"
                        ><i class="fab fa-twitter"></i
                      ></span>
                    </base-button>
                  </share-network>

                  <share-network
                    network="linkedin"
                    :url="speakerPublicURL"
                    :title="item.first_name + ' ' + item.last_name"
                    :description="item.description"
                    :quote="item.first_name + ' ' + item.last_name"
                    :hashtags="getHashtags()"
                    class="mr-2"
                  >
                    <base-button
                      type="vimeo"
                      class="btn-icon-only rounded-circle"
                    >
                      <span class="btn-inner--icon"
                        ><i class="fab fa-linkedin"></i
                      ></span>
                    </base-button>
                  </share-network>
                </div>
              </div>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Option,
  Select,
  Image,
  Carousel,
  CarouselItem,
  Tooltip,
  Skeleton,
  SkeletonItem,
  Collapse,
  CollapseItem,
  InputNumber,
  Steps,
  Step,
  Tabs,
  TabPane,
} from "element-ui";
import { TimeLine, TimeLineItem, LoadingPanel } from "@/components";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import BaseSwitchVue from "../BaseSwitch.vue";

const moment = require("moment-timezone");

export default {
  name: "BaseSpeakerPage",
  components: {
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Image.name]: Image,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Tooltip.name]: Tooltip,
    [Skeleton.name]: Skeleton,
    [SkeletonItem.name]: SkeletonItem,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [InputNumber.name]: InputNumber,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [TimeLine.name]: TimeLine,
    [TimeLineItem.name]: TimeLineItem,
    [BaseSwitchVue]: BaseSwitchVue,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    modals: {
      type: Object,
      default: null,
    },
    hasBookButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      attendee: {
        name: "",
        email: "",
        type: "attendees",
      },
      tags: [],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      textCollapsed: true,
      showSponsors: false,
      showSpeakers: false,
      heroImageLoaded: false,
      activeStep: 0,
      idx: 0,
      loading: false,
    };
  },
  computed: {
    user() {
      let me = null;
      try {
        // Get User Profile
        me = this.$store.getters["profile/me"];
      } catch (error) {
        // Notification
        this.$notify({
          type: "danger",
          message: this.$t('baseSpeakerPage.oopsSomethingWentWrongWithYourAccount')+ '!',
        });
      }
      // return user object
      return me;
    },
    logo() {
      return process.env.VUE_APP_DEFAULT_LOGO;
    },
    speakerPublicURL() {
      const itemId = _.isEmpty(this.item.hash_code)
        ? this.item.id
        : this.item.hash_code;
      return process.env.VUE_APP_BASE_URL + "/speakers/" + itemId;
    },
    speakerShortDescription() {
      return this.item.expertise
        ? this.item.expertise.substring(0, 140) + "..."
        : " ";
    },
  },
  methods: {
    getHeroImage() {
      if (
        this.item.hero_image.includes("http://") ||
        this.item.hero_image.includes("https://")
      ) {
        return this.item.hero_image;
      }
      return process.env.VUE_APP_AWS_S3_URL + this.item.hero_image;
    },
    getHashtags() {
      const tagsName = ["bookmeinscotty"];
      this.tags.forEach((tag) => {
        tagsName.push(tag.name);
      });
      return tagsName.join();
    },
    getHashtagsForEmail() {
      const tagsName = [" #bookmeinscotty"];
      this.tags.forEach((tag) => {
        tagsName.push(" #" + tag.name);
      });
      return tagsName.join();
    },
    getAgendaLabel(val) {
      const tabDate = val.date ?? val.start_at;
      return " ● " + moment(tabDate).format("ddd, D MMM @ HH:mm");
    },
    async copyURL() {
      try {
        await navigator.clipboard.writeText(this.speakerPublicURL);
        swal.fire({
          title: this.$t('baseSpeakerPage.copiedPublicSpeakerLink'),
          html:
             this.$t('general.youCanUseThisLinkToShareItWithWideGroup') + "<br>"  + "</hr>"  +
              "<br><b>" + this.$t('baseSpeakerPage.publicSpeakerLink')+ ":</b><br> <button type='button' class='btn-icon-clipboard text-center'>" +
              this.eventPublicURL() +
              "</button> <br></hr>",
          type: "success",
          confirmButtonClass: "btn btn-outline-danger btn-fill",
          confirmButtonText: this.$t('general.close'),
          buttonsStyling: false,
          icon: "success",
        });
      } catch ($e) {
        alert("Cannot copy");
      }
    },
  },
};
</script>

<style scoped>
#mantra-banner {
  width: 60vw !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.el-tabs--card >>> .el-tabs__header {
  box-shadow: 0 0 0 0 !important;
  border: 0 !important;
  padding-right: 0;
  padding-left: 0;
  overflow: auto !important;
  white-space: nowrap !important;
}
</style>

<template>
  <div class="container-fluid mt-3">
    <div
      class="mb-3 mt--2"
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <!-- Back  -->
      <back-button @click="goBack" />
      <!-- Item Actions  -->
      <a
        class="btn btn-sm btn-primary mr--1 mb--1"
        @click="showActions = !showActions"
      >
        <span slot="label">
          <i class="ni ni-send mr-1" v-if="!showActions" />
          <i class="ni ni-air-baloon mr-1" v-else />
          {{ showActions ? $t('editEventItem.backToEdit') :  $t('editEventItem.quickActions')}}
        </span>
      </a>
    </div>

    <div class="row justify-content-center mt-2">
      <div slot="empty" v-if="loading && !item.uuid">
        <img src="/img/loading.gif" style="height: 100px; width: 100px" />
      </div>
      <div class="col-md-12" v-if="item.uuid && !showActions">
        <validation-observer v-slot="{ handleSubmit }" ref="edit_event_form">
          <form
            ref="edit_event_form"
            @submit.prevent="handleSubmit(submitForm)"
          >
            <el-tabs id="edit-item-tabs" class="mt-2 sticky-tabs-header" v-model="activeName">
              <!-- Details -->
              <el-tab-pane name="details">
                <span slot="label">
                  <i class="el-icon-edit mr-1"></i>
                  {{ $t("editEventItem.details") }}
                </span>
                <edit-event-details
                  :item="item"
                  :dynamicTabs="dynamicTabs"
                  @tagChanged="setSelectedTags($event)"
                  @locationChanged="setLocation($event)"
                  @venueChanged="setVenues($event)"
                  :loading="loading"
                  @unlimitedAttendeesChanged="setUnlimitedAttendees($event)"
                  :activeName="activeName"
                ></edit-event-details>
              </el-tab-pane>

              <!-- Event-Elements Settings-->
              <el-tab-pane name="elements">
                <span slot="label">
                  <i class="el-icon-files mr-1" />{{ $t("editEventItem.elements") }}
                </span>
                <edit-event-elements :item="item"></edit-event-elements>
              </el-tab-pane>

              <!-- Event-Ticketing Settings-->
              <el-tab-pane name="ticketing">
                <span slot="label">
                  <i class="el-icon-c-scale-to-original mr-1" /> {{ $t('editEventItem.ticketing') }}
                </span>
                <edit-event-tickets
                  :item="item"
                  :activeName="activeName"
                  @ticketsChanged="setSelectedTickets($event)"
                ></edit-event-tickets>
              </el-tab-pane>

              <!-- Event-Specific Settings -->
              <el-tab-pane name="options">
                <span slot="label">
                  <i class="el-icon-open mr-1"></i>
                  {{$t("editEventItem.options")}}
                </span>
                <edit-event-options :item="item"></edit-event-options>
              </el-tab-pane>

              <!-- Event-Resources Settings -->
              <el-tab-pane name="resources">
                <span slot="label">
                  <i class="fa fa-puzzle-piece mr-1"></i>
                   {{$t("editEventItem.resources")}}
                </span>
                <edit-event-resources
                  :item="item"
                  @speakersChanged="setSelectedSpeakers($event)"
                  @sponsorsChanged="setSelectedSponsors($event)"
                >
                </edit-event-resources>
              </el-tab-pane>
            </el-tabs>

            <!-- Sticky buttons -->
            <div id="sticky-buttons" class="my-4 mr-0">
              <card type="frame" class="mb-0">
                <div class="row align-content-between">
                  <div class="col text-left mb-1 form-errors">
                    <small
                      v-if="requiredFieldsMissing.length > 0"
                      class="text-danger"
                      >*{{ $t("editEventItem.pleaseFillupAllTheMandatoryFields") }}:
                      <span
                        v-for="missingField in requiredFieldsMissing"
                        :key="missingField"
                      >
                        ● {{ missingField }}
                      </span></small
                    >
                    <small v-if="formAddressInValid()" class="text-danger"
                      >*{{ $t("editEventItem.pleaseFillUpTheEventAddress") }}
                    </small>
                  </div>

                  <div
                    class="col-auto text-right mt-2 mr-3"
                    style="right: 10px"
                  >
                    <!-- Normal - Edit Item -->
                    <div v-if="cloneState" class="mt--3 mb--3">
                      <base-button
                        type="button"
                        class="btn-sm btn-primary"
                        native-type="submit"
                        @click="getFormErrorMessage()"
                        :disabled="formAddressInValid()"
                        v-if="!loading"
                      >
                        <i class="fa fa-save" />
                        {{ $t("editEventItem.duplicateEvent") }}
                      </base-button>
                      <div class="text-center" v-if="loading">
                        <slot name="loading">
                          <i class="fas fa-spinner fa-spin"></i>
                        </slot>
                      </div>
                    </div>

                    <!-- Normal - Edit Item -->
                    <div v-else class="mt--2 mb--3">
                      <base-button
                        type="button"
                        class="btn-sm btn-outline-primary mb-2"
                        native-type="submit"
                        @click="getFormErrorMessage(true)"
                        :disabled="formAddressInValid()"
                        v-if="!loading"
                      >
                        <i class="fa fa-eye" />
                        {{ $t("editEventItem.updateAndPreview") }}
                      </base-button>

                      <base-button
                        type="button"
                        class="btn-sm btn-primary"
                        native-type="submit"
                        @click="getFormErrorMessage()"
                        :disabled="formAddressInValid()"
                        v-if="!loading"
                      >
                        <i class="fa fa-save" />
                        {{ $t("editEventItem.updateEvent") }}
                      </base-button>
                      <div class="text-center" v-if="loading">
                        <slot name="loading" class="text-center">
                          <i class="fas fa-spinner fa-spin text-warning"></i>
                        </slot>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </form>
        </validation-observer>
      </div>
      <div class="col-md-12" v-if="showActions">
        <edit-event-actions :item="item"></edit-event-actions>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Select,
  Option,
  Tabs,
  TabPane,
  Collapse,
  CollapseItem,
  Timeline,
  TimelineItem,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
} from "element-ui";
import BaseButton from "@/components/BaseButton.vue";
import BackButton from "@/components/BackButton.vue";
import formMixin from "@/mixins/form-mixin";
import moment from "moment";
import EditEventDetails from "@/components/EditEvent/EditEventDetails.vue";
import EditEventElements from "@/components/EditEvent/EditEventElements.vue";
import EditEventActions from "@/components/EditEvent/EditEventActions.vue";
import EditEventOptions from "@/components/EditEvent/EditEventOptions.vue";
import EditEventTickets from "@/components/EditEvent/EditEventTickets.vue";
import EditEventResources from "../../../components/EditEvent/EditEventResources.vue";
// import { Slider } from "vue-color";
export default {
  name: "EditEventItem",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    BaseButton,
    BackButton,
    EditEventElements,
    EditEventDetails,
    EditEventActions,
    EditEventOptions,
    EditEventTickets,
    EditEventResources,
  },
  mixins: [formMixin],
  data() {
    return {
      showActions: false,
      loading: false,
      activeName: "details",
      updatedItem: { id: null },
      unlimitedAttendees: false,
      itemToUpdate: null,
      modals: {
        openBooking: false,
        openVideo: false,
      },
      itemId: 0,
      selected_tickets: [],
      selected_speakers: [],
      selected_sponsors: [],
      tags: [],
      venues: [],
      start_at: "",
      end_at: "",
      scheduled_at: "",
      status: "",
      location: null,
      item: {
        relationshipNames: ["category", "tags", "tickets", "venues"],
        id: null,
        uuid: null,
        type: "items",
        name: "",
        theme: "",
        description: "",
        additional_description: "",
        status: "public",
        user: null,
        hero_image: null,
        category: {
          type: "categories",
          id: null,
        },
        organizer: null,
        public_search: false,
        show_on_calendar: true,
        show_on_collection: true,
        show_latest_announcement: false,
        custom_book_button_label: false,
        importance_type: "normal",
        tickets: [],
        tags: [],
        venues: [],
        fileSingle: [],
        fileMultiple: [],
        absorb_fee: [],
        personalized_ticket: false,
        multiple_addresses: false,
      },
      actions: {
        uploadHero: false,
      },
      showCloneItemModal: false,
      cloneState: false,
      updateAndPreview: false,
      requiredFieldsMissing: [],
      refundPolicy: null,
      color: {
        base: "#5e72e4ff",
      },
       all_policies: [
        {
          id: 1,
          label:
            this.$t('editEventItem.attendeesCanReceiveRefundsUpTo1DayBeforeTheEventStartDate'),
        },
        {
          id: 2,
          label:
            this.$t('editEventItem.attendeesCanReceiveRefundsUpTo7DaysBeforeTheEventStartDate'),
        },
        {
          id: 3,
          label:
            this.$t('editEventItem.attendeesCanReceiveRefundsUpTo30DaysBeforeTheEventStartDate'),
        },
        {
          id: 4,
          label:
            this.$t('editEventItem.iDontWantToSpecifyMyRefundPolicyNow'),
        },
        {
          id: 5,
          label: this.$t('editEventItem.noRefundsWeDoNotOfferRefunds')
        },
      ],
      dynamicTabs: {
        editableTabsValue: "",
        editableTabs: [
          {
            title: this.$t('editEvents.newAgendaTab'),
            name: "1",
            date: null,
            content: "",
          },
        ],
        tabIndex: 1,
        faqEditableTabsValue: "",
        faqEditableTabs: [
          {
            title: this.$t('editEvents.newFaqSection'),
            name: "1",
            content: "",
          },
        ],
        faqTabIndex: 1,
      },
    };
  },
  created() {
    this.init();
    this.auth();
    this.cloneState = this.$route.query.clone === "true";
    this.showCloneItemModal = this.cloneState;
    // prevents attachments for Trix Editor:
    document.addEventListener("trix-file-accept", function (event) {
      event.preventDefault();
    });
  },
  computed: {
    publicURL() {
      return process.env.VUE_APP_BASE_URL + "/event/" + this.item.id;
    },
  },
  methods: {
    async init() {
      this.loading = true;

      try {
        const id = (this.item.id = this.$route.params.id);
        let params = {
          filter: {
            ...(this.user ? { user_id: this.user.id } : { user_id: 0 }),
          },
          include: "category,tags,tickets,venues,speakers,sponsors",
        };

        await this.$store
          .dispatch("items/get", { id, params })
          .then(() => (this.item = this.$store.getters["items/item"]));

        if (this.item?.event_agenda) {
          this.dynamicTabs.editableTabs = this.item.event_agenda;
          this.dynamicTabs.tabIndex = this.item.event_agenda?.length;
        }

        if (this.item?.event_faqs) {
          this.dynamicTabs.faqEditableTabs = this.item.event_faqs;
          this.dynamicTabs.faqTabIndex = this.item.event_faqs?.length;
        }

        if (this.cloneState) {
          delete this.item.start_at;
          delete this.item.end_at;
        }
        this.start_at = this.item.start_at;
        this.end_at = this.item.end_at;

        this.loading = false;
      } catch (error) {
        console.debug("Init data >>",error);

        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong')+ '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async auth() {
      try {
        // Fetch User data
        // await this.$store.dispatch("profile/me");
        this.user = await { ...this.$store.getters["profile/me"] };
        //this.getCategories();
      } catch (error) {
        console.debug("Auth data>>",error);
        // Notification
        this.$notify({
          type: "danger",
          message:this.$t('editEventItem.oopsSomethingWentWrongWithYourAccount')+'!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async submitForm() {
      this.loading = true;

      try {
        const itemToUpdate = JSON.parse(JSON.stringify(this.item));
        itemToUpdate.tags = [];
        this.tags.forEach((tag) => {
          itemToUpdate.tags.push({
            id: tag,
            type: "tags",
          });
        });

        if (!itemToUpdate.custom_book_button_label) {
          delete itemToUpdate.book_button_label;
          delete itemToUpdate.book_button_color;
          delete itemToUpdate.book_promotion_label;
        }

        itemToUpdate.start_at = moment(
          JSON.parse(JSON.stringify(this.item.start_at))
        ).format("YYYY-MM-DD HH:mm:ss");
        itemToUpdate.end_at = moment(
          JSON.parse(JSON.stringify(this.item.end_at))
        ).format("YYYY-MM-DD HH:mm:ss");

        itemToUpdate.tickets = [];
        this.selected_tickets.forEach((tckt) => {
          const ticket = JSON.parse(JSON.stringify(tckt));
          ticket.type = "tickets";
          if (ticket.sale_range.indexOf("to") > -1) {
            ticket.start_sale_at = ticket.sale_range
              .split("to")[0]
              .slice(0, -1);
            ticket.end_sale_at = ticket.sale_range.split("to")[1].slice(1);
            if (ticket.end_sale_at === "null") ticket.end_sale_at = null;
          } else {
            ticket.start_sale_at = ticket.sale_range;
            ticket.end_sale_at = null;
          }
          // if (ticket.price === 0 || ticket.price === "0") {
          //   ticket.personalized_ticket = false;
          // }
          if (!ticket.total_quantity || ticket.total_quantity < 0) {
            ticket.total_quantity = -1;
          }
          delete ticket.sale_range;
          delete ticket.defaultDate;
          itemToUpdate.tickets.push(ticket);
        });

        itemToUpdate.event_speakers = this.selected_speakers;
        itemToUpdate.event_sponsors = this.selected_sponsors;

        // this.selected_speakers.forEach((spkr) => {
        //   const speaker = JSON.parse(JSON.stringify(spkr));
        //   speaker.type = "speakers";
        //   itemToUpdate.speakers.push(speaker);
        // });
        // console.log(itemToUpdate)
        this.all_policies.forEach((policy) => {
          if (policy.id === this.refundPolicy) {
            itemToUpdate.refund_policy = policy.label;
          }
        });

        if (itemToUpdate.event_type === "physical") {
          delete itemToUpdate.virtual_link;
        } else if (itemToUpdate.event_type === "tba") {
          itemToUpdate.total_capacity = -1;
        } else if (
          itemToUpdate.event_type === "virtual" &&
          this.unlimitedAttendees
        ) {
          itemToUpdate.total_capacity = -1;
        }
        // if multiple_addresses is selected, send venues array, else send address as venue
        if (
          itemToUpdate.event_type === "physical" ||
          itemToUpdate.event_type === "hybrid"
        ) {
          if (itemToUpdate.multiple_addresses) {
            itemToUpdate.venues = [];
            this.venues.forEach((venue) => {
              itemToUpdate.venues.push({
                id: venue,
                type: "venues",
              });
            });
            delete itemToUpdate.venue;
          } else if (this.location) {
            itemToUpdate.venue = {
              address_name: this.location.address,
              long: this.location.center[0],
              lat: this.location.center[1],
            };
            delete itemToUpdate.venues;
          }
        }

        // Prepare the Agenda Dynamic Tabs
        if (!this.isFreeUser() && this.dynamicTabs.editableTabs.length > 0) {
          const [first] = this.dynamicTabs.editableTabs;
          if (!_.isNull(first.date) && first.name !== "New Agenda Tab") {
            itemToUpdate.event_agenda = this.dynamicTabs.editableTabs;
          }
        } else itemToUpdate.event_agenda = undefined;

        // Prepare the FAQ Dynamic Tabs
        if (!this.isFreeUser() && this.dynamicTabs.faqEditableTabs.length > 0) {
          const [first] = this.dynamicTabs.faqEditableTabs;
          if (
            !_.isNull(first.content !== "New FAQ Section") &&
            first.title !== "New FAQ Section"
          ) {
            itemToUpdate.event_faqs = this.dynamicTabs.faqEditableTabs;
          }
        } else itemToUpdate.event_faqs = undefined;

        // Duplicate/Clone event
        if (this.cloneState) {
          delete itemToUpdate.id;
          let clonedItem;
          await this.$store
            .dispatch("items/add", itemToUpdate)
            .then(() => {
              // Notification
              clonedItem = this.$store.getters["items/item"];
              this.$notify({
                type: "success",
                message: this.$t('general.itemUpdatedSuccessfully')+ '.',
              });
              // Spin Flag
              this.loading = false;
            })
            .then(() => {
              // Redirect to preview
              this.$router.push({
                name: "Event Preview",
                params: { id: clonedItem.id },
              });
            });
        } else if (this.updateAndPreview) {
          // Update and Preview event
          await this.$store.dispatch("items/update", itemToUpdate).then(() => {
            // Notification
            this.$notify({
              type: "success",
              message: this.$t('editEventItem.itemAddedSuccessfully')+'.',
            });
            // Flag
            this.loading = false;

            // Redirect to preview
            this.$router.push({
              name: "Event Preview",
              params: { id: this.item.id },
            });
          });
        } else {
          // Update event
          await this.$store.dispatch("items/update", itemToUpdate);
          // Notification
          this.$notify({
            type: "success",
            message: this.$t('editEventItem.itemAddedSuccessfully')+'.',
          });
          // Flag
          this.loading = false;
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong') + '!',
        });
        // Flag
        this.loading = false;
        // Error validations
        this.setApiValidation(error?.response?.data.errors);
      }
    },
    goBack() {
      this.$router.push({ name: "List Events" });
    },
    setSelectedTags(tags) {
      this.tags = tags;
    },
    setLocation(location) {
      this.location = location;
    },
    setVenues(venues) {
      this.venues = venues;
    },
    setUnlimitedAttendees(unlimitedAttendees) {
      this.unlimitedAttendees = unlimitedAttendees;
    },
    setSelectedTickets(tickets) {
      this.selected_tickets = tickets;
    },
    setSelectedSpeakers(speakers) {
      this.selected_speakers = speakers;
    },
    setSelectedSponsors(sponsors) {
      this.selected_sponsors = sponsors;
    },
    formAddressInValid() {
      return (
        // ((!this.item.total_capacity || this.item.total_capacity < 0) &&
        //   !(
        //     this.item.event_type === "tba" ||
        //     (this.item.event_type === "virtual" && this.unlimitedAttendees)
        //   )) ||
        (this.item.event_type === "physical" ||
          this.item.event_type === "hybrid") &&
        this.location &&
        !this.location.address
        //   ||
        // ((this.item.event_type === "virtual" ||
        //   this.item.event_type === "hybrid") &&
        //   !this.item.virtual_link)
      );
    },
    getFormErrorMessage(updateAndPreview) {
      this.requiredFieldsMissing = [];
      for (const key in this.$refs.edit_event_form.fields) {
        if (this.$refs.edit_event_form.fields.hasOwnProperty(key)) {
          const item = this.$refs.edit_event_form.fields[key];

          if (item.invalid && item.required) {
            this.requiredFieldsMissing.push(key);
          }
        }
      }
      if (updateAndPreview && this.requiredFieldsMissing.length === 0) {
        this.updateAndPreview = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./../../../../node_modules/flatpickr/dist/flatpickr.min.css";

#edit-item-tabs .el-tabs__header {
  padding: 5px 15px 10px 15px;
  margin-bottom: 20px;
  box-shadow: 0 0 0 0 !important;
  border: 1px solid #f5f6f8;
  border-radius: calc(0.75rem - 1px);
}

.el-tabs__item {
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 600;
}

.el-tabs__item.is-active {
  color: #5e72e4ff;
}
.el-tabs__active-bar {
  background-color: #5e72e4ff !important;
}

.trix-button-group.trix-button-group--file-tools {
  display: none;
}
</style>

<style scoped>
.el-tabs--card >>> .el-tabs__header {
  box-shadow: 0 0 0 0 !important;
  border: 0 !important;
  padding-right: 0;
  padding-left: 0;
}

.el-tabs__header >>> el-tabs__new-tab {
  color: #5e72e4ff !important;
}

.custom-form-radius {
  border-radius: calc(0.75rem - 1px) !important;
}
.form-errors {
  display: flex;
  flex-direction: column;
}
</style>

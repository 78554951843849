<template>
  <div class="container-fluid mt-3">
    <back-button @click="goBack" />

    <div class="row mt-3">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">Add Item</h3>
            </div>
            <!-- <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
                >Back to list</base-button
              >
            </div> -->
          </div>
          <div class="card-body">
            <form ref="profile_form" @submit.prevent="handleSubmit">
              <div class="form-group">
                <label class="form-control-label"> Picture </label>
                <div v-if="hero_image" class="profile-image card-img pb-4">
                  <img
                    :src="`${hero_image}`"
                    class="profile-image base-profile-image"
                  />
                </div>
                <div v-else class="profile-image">
                  <img src="/img/placeholder.jpg" class="base-profile-image" />
                </div>
                <div class="image-upload">
                  <base-button
                    v-if="hero_image"
                    type="button"
                    class="btn btn-sm btn-danger"
                    @click="removeImage"
                  >
                    <span>
                      <i class="fa fa-times" />
                      Remove
                    </span>
                  </base-button>
                  <base-button type="button" class="btn btn-sm btn-primary">
                    <label v-if="!hero_image" for="imageInput" class="mb-0"
                      >Select image</label
                    >
                    <label v-else for="imageInput" class="mb-0">Change</label>
                    <input
                      id="imageInput"
                      ref="imageInput"
                      accept="image/*"
                      type="file"
                      style="display: none"
                      @change="onSelectFile"
                    />
                  </base-button>
                </div>
              </div>
              <validation-error :errors="apiValidationErrors.attachment" />

              <base-input
                label="Name"
                prepend-icon="fas fa-user"
                v-model="item.name"
              />
              <validation-error :errors="apiValidationErrors.name" />

              <base-input label="Description">
                <!-- <base-html-input v-model="item.description" name="editor" /> -->
                <base-html-input
                          label="Description"
                          v-model="item.description"
                        />
              </base-input>
              <validation-error :errors="apiValidationErrors.description" />

              <base-input label="Category">
                <el-select
                  name="category"
                  v-model="item.category.id"
                  prepend-icon="fas fa-user"
                  placeholder="Select..."
                >
                  <el-option
                    v-for="single_category in all_categories"
                    :key="single_category.id"
                    :value="single_category.id"
                    :label="single_category.name"
                  >
                  </el-option>
                </el-select>
              </base-input>

              <base-input label="Tags">
                <el-select v-model="tags" multiple placeholder="Select...">
                  <el-option
                    v-for="single_tag in all_tags"
                    :key="single_tag.id"
                    :value="single_tag.id"
                    :label="single_tag.name"
                  >
                  </el-option>
                </el-select>
              </base-input>

              <base-input label="Status">
                <base-radio v-model="item.status" name="published" class="mb-3">
                  Published
                </base-radio>
                <base-radio v-model="item.status" name="draft" class="mb-3">
                  Draft
                </base-radio>
                <base-radio v-model="item.status" name="archive" class="mb-3">
                  Archive
                </base-radio>
              </base-input>

              <base-input label="Show on homepage?">
                <base-switch
                  class="mr-1"
                  v-model="item.public_search"
                ></base-switch>
              </base-input>

              <base-input label="Date">
                <flat-picker
                  slot-scope="{ focus, blur }"
                  @on-open="focus"
                  @on-close="blur"
                  :config="{ allowInput: true }"
                  class="form-control datepicker"
                  v-model="item.start_at"
                >
                </flat-picker>
              </base-input>
              <validation-error :errors="apiValidationErrors.start_at" />

              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                  Add Item
                </base-button>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import HtmlEditor from "@/components/Inputs/BaseHtmlInput";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import BackButton from "@/components/BackButton.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    BaseButton,
    ValidationError,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
    BackButton,
  },

  mixins: [formMixin],

  data() {
    return {
      file: null,
      hero_image: null,
      all_categories: [],
      all_tags: [],
      tags: [],
      item: {
        type: "items",
        name: "",
        description: "",
        status: "published",
        public_search: false,
        start_at: new Date(),
        hero_image: null,
        category: {
          type: "categories",
          id: null,
        },
        tags: [],
        relationshipNames: ["category", "tags"],
        user: null,
      },
    };
  },
  created() {
    this.getCategories();
    this.getTags();
  },

  methods: {
    async getCategories() {
      try {
        await this.$store.dispatch("categories/list");
        this.all_categories = await this.$store.getters["categories/dropdown"];

        if (this.all_categories && this.all_categories.length) {
          this.item.category.id = this.all_categories[0].id;
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong')+ '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getTags() {
      try {
        await this.$store.dispatch("tags/list");
        this.all_tags = await this.$store.getters["tags/dropdown"];

        if (this.all_tags && this.all_tags.length) {
          this.tags.push(this.all_tags[0].id);
          this.item.tags = [];

          this.tags.forEach((tag) => {
            this.item.tags.push({
              id: tag,
              type: "tags",
            });
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong')+ '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    goBack() {
      this.$router.push({ name: "List Items" });
    },

    removeImage() {
      this.hero_image = null;
    },

    async handleSubmit() {
      // await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
      // if (this.user.roles[0].name === "member") {
      //   this.$notify({
      //     type: "danger",
      //     message: "Oops, you are not authorized to do this action.",
      //   });
      //   return;
      // }
      this.item.description = this.item.description;
      this.item.start_at = moment(this.item.start_at).format("YYYY-MM-DD");
      this.item.tags = [];
      this.tags.forEach((tag) => {
        this.item.tags.push({
          id: tag,
          type: "tags",
        });
      });
      try {
        delete this.item.id;
        await this.$store.dispatch("items/add", this.item);
        this.item = await this.$store.getters["items/item"];

        if (this.file) {
          await this.$store.dispatch("items/upload", {
            item: this.item,
            hero_image: this.file,
          });

          this.item.hero_image = await this.$store.getters["items/url"];

          await this.$store.dispatch("items/update", this.item);
          this.item = await this.$store.getters["items/item"];
        }

        this.$notify({
          type: "success",
          message: "Item added successfully.",
        });
        this.goBack();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong') + '!',
        });
        this.setApiValidation(error.response.data.errors);
        if (this.item.id) {
          await this.$store.dispatch("items/destroy", this.item.id);
        }

        this.item.start_at = new Date();
      }
    },

    async onSelectFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = file;
      };

      reader.readAsDataURL(file);
    },
  },
};
</script>

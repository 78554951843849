<template>
  <div class="map-holder">
    <div v-if="!horizontalLayout">
      <div class="form-group">
        <label class="form-control-label" v-show="search">{{
          $t("baseMapBox.address")
        }}</label>
        <div id="geocoder" class="geocoder" v-show="search" />
        <div
          id="map"
          :style="{ height: height }"
          style="border-radius: 0.75rem"
        />
      </div>

      <!-- Event Coordinates -->
      <base-alert
        type="primary"
        v-if="location.address"
        style="margin-top: 1em"
      >
        <div class="d-flex justify-content-between pt-1 pb-2">
          <div>
            <h6 class="text-muted text-white surtitle ml-1">
              <span class="text-white font-weight-bold">
                <i class="fa fa-map-marked-alt" />
              </span>
              {{ label }}
            </h6>
          </div>
          <!-- Direction shortcuts -->
          <div
            v-show="enableShortcuts"
            class="text-right text-white text-muted mt--1"
          >
            <a
              :href="getGmapsDirectionsURL(lat, long, 'driving')"
              target="_blank"
              class="text-white"
              ><i class="fa fa-car" /> |
            </a>
            <a
              :href="getGmapsDirectionsURL(lat, long, 'bus')"
              target="_blank"
              class="text-white"
              ><i class="fa fa-bus" /> |
            </a>
            <a
              :href="getGmapsDirectionsURL(lat, long, 'walking')"
              target="_blank"
              class="text-white"
              ><i class="fa fa-walking" /> |
            </a>
            <a
              :href="getGmapsDirectionsURL(lat, long, 'bicycle')"
              target="_blank"
              class="text-white"
              ><i class="fa fa-bicycle"
            /></a>
          </div>
        </div>
        <div class="map-location-address bg-gradient-primary">
          <span>{{ location.address }}</span>
        </div>
      </base-alert>
    </div>

    <div v-else>
      <card class="bg-white shadow-none no-gutters">
        <div class="row justify-content-center pl-0 pr-0">
          <div class="col">
            <div class="form-group">
              <label class="form-control-label" v-show="search">
                Address *
              </label>
              <div id="geocoder" class="geocoder" v-show="search" />
              <!-- Load Mapbox-->
              <div
                id="map"
                class="col"
                :style="{ height: height }"
                style="border-radius: 0.75rem"
              />
            </div>
          </div>

          <div class="col-auto">
            <base-alert type="primary" v-if="location.address">
              <div class="d-flex justify-content-between pt-1 pb-2">
                <div>
                  <h6 class="text-muted text-white surtitle ml-1">
                    <span class="text-white font-weight-bold">
                      <i class="fa fa-map-marked-alt" />
                    </span>
                    {{ label }}
                  </h6>
                </div>
                <!-- Direction shortcuts -->
                <div
                  v-show="enableShortcuts"
                  class="text-right text-white text-muted mt--1"
                >
                  <a
                    :href="getGmapsDirectionsURL(lat, long, 'driving')"
                    target="_blank"
                    class="text-white"
                    ><i class="fa fa-car" /> |
                  </a>
                  <a
                    :href="getGmapsDirectionsURL(lat, long, 'bus')"
                    target="_blank"
                    class="text-white"
                    ><i class="fa fa-bus" /> |
                  </a>
                  <a
                    :href="getGmapsDirectionsURL(lat, long, 'walking')"
                    target="_blank"
                    class="text-white"
                    ><i class="fa fa-walking" /> |
                  </a>
                  <a
                    :href="getGmapsDirectionsURL(lat, long, 'bicycle')"
                    target="_blank"
                    class="text-white"
                    ><i class="fa fa-bicycle"
                  /></a>
                </div>
              </div>

              <h6 class="map-location-venue ml-1 mt-2 mb-2">
                <span class="text-white font-weight-bold"> Venue </span> <br />
                <span class="text-white font-weight-bold surtitle"
                  >{{ location.address }}
                </span>
              </h6>
              <div class="map-location-address bg-gradient-primary">
                <span class="text-white font-weight-bold"> Location </span>
                <br />
                <span>{{ location.address }}</span>
              </div>
            </base-alert>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

// eslint-disable-next-line
import mapboxgl from "mapbox-gl";
// import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
export default {
  name: "base-mapbox",
  props: {
    label: {
      type: String,
      default: "Final Location",
    },
    placeholder: {
      type: String,
      default: "Type to search for an address",
    },
    height: {
      default: "300px",
      type: String,
    },
    width: {
      default: "99%",
      type: String,
    },
    search: {
      type: Boolean,
      default: true,
    },
    long: {
      default: null,
      type: String,
    },
    lat: {
      default: null,
      type: String,
    },
    draggablePin: {
      default: true,
      type: Boolean,
    },
    scrollable: {
      default: false,
      type: Boolean,
    },
    navigationBtn: {
      default: false,
      type: Boolean,
    },
    enableShortcuts: {
      default: false,
      type: Boolean,
    },
    horizontalLayout: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      location: { center: [0, 0], address: null },
      access_token: process.env.VUE_APP_MAPBOX_TOKEN,
      map: {},
    };
  },

  watch: {
    location: {
      deep: true,
      handler(value) {
        this.$emit("getCoordinatesValues", value);
      },
    },
    lat: {
      handler: "setNewCoords",
      immediate: false,
    },
    long: {
      handler: "setNewCoords",
      immediate: false,
    },
  },
  methods: {
    initMap() {
      mapboxgl.accessToken = this.access_token;

      this.map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/light-v10",
        center: this.location.center,
        zoom: 9,
      });
    },
    resizeMap() {
      if (this.map) this.map.remove();

      mapboxgl.accessToken = this.access_token;

      this.map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/light-v10",
        center: this.location.center,
        zoom: 9,
      });

      const containerSize = document.getElementsByName("map");

      containerSize.style.width = "720px";
      containerSize.style.height = "500px";
    },
    async createMap(pos) {
      if (pos && pos.coords) {
        this.location.center = [pos.coords.longitude, pos.coords.latitude];
      }
      try {
        mapboxgl.accessToken = this.access_token;

        this.initMap();

        // change the map wrapper size
        const resizeMap = (pos) => {
          const containerDiv = document.getElementById("map");

          containerDiv.style.width = this.width;
          containerDiv.style.height = this.height;

          if (map) map.remove();
          this.init(pos);
        };

        const geocoder = new MapboxGeocoder({
          accessToken: this.access_token,
          mapboxgl: mapboxgl,
          marker: false,
          language:
            navigator.language === "zz-ZZ" ? "en-AU" : navigator.language,
        });

        document
          .getElementById("geocoder")
          .appendChild(geocoder.onAdd(this.map));

        let marker = new mapboxgl.Marker({
          draggable: this.draggablePin,
          color: "orange",
        })
          .setLngLat(this.location.center)
          .addTo(this.map);

        if (this.navigationBtn) {
          this.map.addControl(new mapboxgl.NavigationControl());
        }

        // disable map zoom when using scroll
        if (this.scrollable) {
          this.map.scrollZoom.disable();
        }

        marker.on("dragend", (e) => {
          this.location.center = Object.values(e.target.getLngLat());
          this.getLocation();
        });
        geocoder.on("clear", (e) => {
          this.location.address = null;
        });
        geocoder.on("result", (e) => {
          marker.setLngLat(e.result.center).addTo(this.map);
          this.location.center = e.result.center;
          this.location.address = e.result.place_name;
        });
      } catch (err) {
        console.log("map error", err);
      }
    },
    async getLocation() {
      const center = this.location.center;
      const access_token = this.access_token;
      try {
        await this.$store.dispatch("items/getAddress", {
          center,
          access_token,
        });
        const res = await this.$store.getters["items/address"];
        this.location.address =
          res.features[0] && res.features[0].place_name
            ? res.features[0].place_name
            : null;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("general.oopsSomethingWentWrong") + "!",
        });
      }
    },
    setNewCoords() {
      this.createMap({
        coords: {
          longitude: parseFloat(this.long),
          latitude: parseFloat(this.lat),
        },
      });
      this.getLocation();
    },
    getGmapsDirectionsURL(long, lat, mode) {
      return (
        "https://maps.google.com/?saddr=Current+Location&daddr=" +
        long +
        "," +
        lat +
        "&mode=" +
        mode
      );
    },
  },
  mounted() {
    if (this.long && this.lat) {
      this.createMap({
        coords: {
          longitude: parseFloat(this.long),
          latitude: parseFloat(this.lat),
        },
      });

      this.getLocation();
    } else {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      navigator.geolocation.getCurrentPosition(
        this.createMap,
        this.createMap,
        options
      );
    }
  },
};
</script>

<style lang="scss">
canvas .mapboxgl-canvas {
  width: 900px !important;
}
</style>

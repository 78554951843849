<template>
  <footer class="footer footer-auto-bottom px-4">
    <div class="row align-items-center justify-content-between">
      <div class="col-md-6">
        <ul class="nav nav-footer">
          <li class="nav-item">
            <a href="#" class="nav-link" target="_blank" rel="noopener">
              <small class="text-muted text-uppercase">{{
                $t("appFooterNav.aboutUs")
              }}</small>
            </a>
          </li>
          <li class="nav-item">
            <a
              href="https://www.bookmeinscotty.com/terms-of-use/"
              class="nav-link"
              target="_blank"
              rel="noopener"
            >
              <small class="text-muted text-uppercase">{{
                $t("appFooterNav.termsOfUse")
              }}</small>
            </a>
          </li>
          <li class="nav-item">
            <a
              href="https://www.bookmeinscotty.com/terms-and-conditions/"
              class="nav-link"
              target="_blank"
              rel="noopener"
            >
              <small class="text-muted text-uppercase">{{
                $t("appFooterNav.termsAndConditions")
              }}</small>
            </a>
          </li>
        </ul>
      </div>

      <div class="col-md-4 text-right mr-5">
        <small class="text-muted text-uppercase">
          © {{ year }} | {{ $t("appFooterNav.madeWith") }}
          <i class="fa fa-heart heart" /> {{ $t("appFooterNav.by") }}
        </small>
        <a
          href="https://www.bmisoftware.gr"
          class="font-weight-bold"
          target="_blank"
          rel="noopener"
        >
          <small class="text-muted text-uppercase text-underline mr-2"
            >BMIS Team</small
          >
        </a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>

<template>
  <div>
    <!-- Additional Metadata -->
    <div class="card">
      <div class="card-header">
        <h1>{{$t("userPreferencesCard.updatePreferences")}}</h1>
      </div>
      <div class="card-body">
        <form ref="additional_meta" @submit.prevent="updateUserInfos">
          <base-input :label="$t('userPreferencesCard.timeZone')" required>
            <el-select
              v-model="user.timezone"
              filterable
              prepend-icon="fas fa-user"
              :placeholder="$t('general.select')+'...'"
            >
              <el-option
                v-for="item in allTimezones"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
          <!-- <validation-error :errors="apiValidationErrors.password" /> -->

          <base-premium-overlay :enabled="isFreeUser()">
            <div>

              <base-input :label="$t('userPreferencesCard.enableEmailNotification')">
                <base-switch
                    class="mr-1"
                    v-model="email_notifications"
                    :description="{
                    show: true,
                    content:
                      $t('userPreferencesCard.receiveRealTimeNotificationForActions'),
                  }"
                ></base-switch>
              </base-input>

              <base-input
                  v-if="email_notifications"
                  type="token"
                  name="slack_token"
                  :label="$t('userPreferencesCard.emailNotification')"
                  autocomplete="on"
                  class="mb-3"
                  prepend-icon="fas fa-envelope"
                  v-model="email_list"
                  :placeholder="$t('userPreferencesCard.emailNotificationList')"
              />

              <base-input :label="$t('userPreferencesCard.enableNotificationInSlackChannel')">
                <base-switch
                  class="mr-1"
                  v-model="slack_notifications"
                  :description="{
                    show: true,
                    content:
                      $t('userPreferencesCard.receiveRealTimeNotificationForActions'),
                  }"
                ></base-switch>
              </base-input>

              <base-premium-overlay
                :enabled="isFreeUser()"
                :badge-label="$t('userPreferencesCard.comingSoon')"
                badge-style="warning"
                :badge-message="$t('userPreferencesCard.thisIntegrationWillBeReleasedSoonFeatureIsUnderDevelopment')"
              >
                <base-input
                  v-if="slack_notifications"
                  type="token"
                  name="slack_token"
                  :label="$t('userPreferencesCard.slackNotificationWebhook')"
                  autocomplete="on"
                  class="mb-3"
                  prepend-icon="fab fa-slack"
                  v-model="slack_token"
                  :placeholder="$t('userPreferencesCard.slackNotificationToken')"
                  :disabled="isFreeUser()"
                />
              </base-premium-overlay>

            </div>
          </base-premium-overlay>
          <div class="my-4">
            <base-button
              type="button"
              class="btn btn-sm btn-primary"
              native-type="submit"
              :disabled="!user.timezone"
            >
             {{$t('userPreferencesCard.updateDetails')}}
            </base-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option, Tooltip } from "element-ui";
import moment from "moment-timezone";
import BasePremiumOverlay from "../../../components/Premium/BasePremiumOverlay.vue";

export default {
  name: "UserPreferences",

  components: {
    BaseInput,
    BaseButton,
    ValidationError,
    BasePremiumOverlay,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
  },

  mixins: [formMixin],

  props: {
    user: Object,
  },

  data() {
    return {
      me: {},
      slack_notifications: false,
      slack_token: null,
      snipcart_token: null,
      email_list: null,
      email_notifications: false,
    };
  },
  computed: {
    allTimezones() {
      return moment.tz.names();
    },
  },
  created() {

    this.email_notifications  = this.user.email_notifications === null ? false : true;
    this.email_list           = this.user.email_list;
    this.slack_notifications  = this.user.slack_notifications === null ? false : true;
    this.slack_token          = this.user.slack_token;
    this.snipcart_token       = this.user.snipcart_token;
  },
  methods: {
    async updateUserInfos() {
      const dataToUpdate = {
        timezone: this.user.timezone,
        id: this.user.id,
        type: this.user.type,
        metadata: {
          email_notifications: this.email_notifications,
          email_list: this.email_list,
          slack_notifications: this.slack_notifications,
          slack_token: this.slack_token,
          snipcart_token: this.snipcart_token,
        },
      };
      try {
        await this.$store.dispatch("users/update", dataToUpdate);

        this.resetApiValidation();

        this.$notify({
          type: "success",
          message: this.$t('userPreferencesCard.userProfileUpdatedSuccessfully')+'.',
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong') + '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    checkGoogleEmail(email) {
      const googleEmailRegex =
        /^[a-z0-9]((\.|\+)?[a-z0-9]){5,}@g(oogle)?mail\.com$/g;
      return googleEmailRegex.test(email);
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('card',[_c('div',{staticClass:"row align-items-center",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"col-md-12 order-md-0 order-first"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("editEvents.eventSpecificSetting")))])])]),_c('div',{staticClass:"card-body mt-0 ml--2"},[_c('base-input',{attrs:{"label":_vm.$t('editEvents.showOnCalendar')}},[_c('base-switch',{staticClass:"mr-0",attrs:{"description":{
              show: true,
              content: _vm.$t('editEvents.displayThisEventInPublicMyCalendar'),
            },"type":"danger"},model:{value:(_vm.item.show_on_calendar),callback:function ($$v) {_vm.$set(_vm.item, "show_on_calendar", $$v)},expression:"item.show_on_calendar"}})],1)],1),_c('div',{staticClass:"card-body mt--5 ml--2"},[_c('base-input',{attrs:{"label":_vm.$t('editEvents.showOnCollection')}},[_c('base-switch',{staticClass:"mr-0",attrs:{"description":{
              show: true,
              content: _vm.$t('editEvents.displayThisEventInPublicMyCollections'),
            },"type":"danger"},model:{value:(_vm.item.show_on_collection),callback:function ($$v) {_vm.$set(_vm.item, "show_on_collection", $$v)},expression:"item.show_on_collection"}})],1)],1),_c('div',{staticClass:"card-body mt--5 ml--2"},[_c('base-input',{attrs:{"label":_vm.$t('editEvents.showAnnouncementOnEventPublicPage')}},[_c('base-switch',{staticClass:"mr-0",attrs:{"description":{
              show: false,
              content: _vm.$t(
                'editEvents.displayLatestAnnouncementOnEventPublicPage'
              ),
            },"type":"danger"},model:{value:(_vm.item.show_latest_announcement),callback:function ($$v) {_vm.$set(_vm.item, "show_latest_announcement", $$v)},expression:"item.show_latest_announcement"}})],1)],1)])],1),_c('div',{staticClass:"col-md-6 mb-0"},[_c('card',[_c('div',{staticClass:"row align-items-center",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"col-md-12 order-md-0 order-first"},[_c('p',{staticClass:"mb-0"},[_vm._v("Event-specific customization")])])]),_c('base-premium-overlay',{attrs:{"enabled":_vm.isFreeUser()}},[_c('div',{staticClass:"card-body mt--5 ml--2"},[_c('base-input',{attrs:{"label":"Customize Ticket Booking Button"}},[_c('base-switch',{staticClass:"mr-0",attrs:{"description":{
                show: false,
                content:
                  'Rename the ( \'Book Me In Scotty\' ) to a custom label and create a more personalized Call-To-Action ticketing Button that reinforce your Brand and Improve the selling experience.',
              },"type":"danger"},model:{value:(_vm.item.custom_book_button_label),callback:function ($$v) {_vm.$set(_vm.item, "custom_book_button_label", $$v)},expression:"item.custom_book_button_label"}})],1),(_vm.item.custom_book_button_label)?_c('div',[_c('base-input',{attrs:{"label":"Book Button Label","placeholder":"Add Book Button label"},model:{value:(_vm.item.book_button_label),callback:function ($$v) {_vm.$set(_vm.item, "book_button_label", $$v)},expression:"item.book_button_label"}}),_c('base-input',{attrs:{"label":"Book Promotion Label","placeholder":"Add Promotion label"},model:{value:(_vm.item.book_promotion_label),callback:function ($$v) {_vm.$set(_vm.item, "book_promotion_label", $$v)},expression:"item.book_promotion_label"}}),_c('base-input',{attrs:{"label":"Book Button Color"}},[_c('color-picker',{staticStyle:{"width":"100%"},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1),_vm._t("label",function(){return [_c('span',{staticClass:"text-uppercase text-muted text-sm-center"},[_vm._v("Custom Button Preview")])]}),_c('card',{staticClass:"mt-2",attrs:{"id":"cta","type":"frame"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h6',{staticClass:"surtitle text-muted mt-3"},[_c('i',{staticClass:"fas fa-ticket"}),(_vm.item.book_promotion_label)?_c('span',[_vm._v(" "+_vm._s(_vm.item.book_promotion_label)+" ")]):_c('span',[_vm._v("Book your ticket with")])]),_c('div',[_c('base-button',{staticClass:"mx-auto",staticStyle:{"cursor":"default"},style:({
                      background: _vm.item.book_button_color,
                      'border-color': _vm.item.book_button_color,
                    }),attrs:{"id":"base-cta-ticket-booking","data-testid":"base-cta-ticket-booking","type":"danger","data-toggle":"tooltip","block":""}},[(_vm.item.book_button_label)?_c('span',[_vm._v(" "+_vm._s(_vm.item.book_button_label)+" ")]):_c('span',[_c('img',{staticStyle:{"max-width":"1.3rem","max-height":"1.2rem","padding-right":"10px"},attrs:{"src":"/img/brand/bmis-default-logo-cta.png"}})])])],1)])])],2):_vm._e()],1)])],1),_c('card',[_c('div',{staticClass:"row align-items-center",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"col-md-12 order-md-0 order-last"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("editEvents.eventSpecificInsights")))])])]),_c('div',{staticClass:"card-body mt--2 ml--2"},[_c('base-input',{attrs:{"label":_vm.$t('editEvents.googleAnalyticsTrackingCode'),"placeholder":"GA4 Tracking ID ( G-E903XXXX )"},model:{value:(_vm.item.google_analytics_id),callback:function ($$v) {_vm.$set(_vm.item, "google_analytics_id", $$v)},expression:"item.google_analytics_id"}}),_c('div',[_c('base-alert',{attrs:{"type":"secondary"}},[_c('el-timeline',{staticClass:"ml--5 mt-2 mb--3"},[_c('el-timeline-item',{attrs:{"icon":"fa fa-info-circle","color":_vm.color.base,"size":"large"}},[_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("editEvents.howToSetupAnalyticsForGA4"))+" "),_c('a',{attrs:{"href":"https://support.google.com/analytics/answer/9304153?hl=en","target":"_blank"}},[_vm._v(" [ "+_vm._s(_vm.$t("editEvents.officialTutorial"))+" ] ")])])])],1)],1)],1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="card">
    <div class="card-header">
      <h1>{{$t("userInvoiceCard.updateInvoiceInfos")}}</h1>
    </div>
    <div class="card-body">
      <validation-observer v-slot="{ handleSubmit }" ref="user_invoice_form">
        <form
          ref="user_invoice_form"
          @submit.prevent="handleSubmit(submitForm)"
        >
          <base-input :label="$t('userInvoiceCard.isThisBusinessPayment')">
            <base-switch
              class="mr-1"
              v-model="vendor_toggle"
              :description="{
                show: true,
                content:
                $t('userInvoiceCard.includeFormalGeneratedInvoiceForEveryPaymentTransfer'),
              }"
            />
          </base-input>
          <div class="form-group">
            <base-input
              :label="$t('userInvoiceCard.fullName')"
              :placeholder="$t('userInvoiceCard.yourName')"
              v-model="vendor.name"
              name="Name"
              class="mb-3"
              required
            />

            <validation-error :errors="apiValidationErrors.name" />

            <base-input
              :label="$t('userInvoiceCard.bankAccount')"
              :placeholder="$t('userInvoiceCard.provideValidBankAccount')"
              v-model="vendor.bank_account"
              name="Bank Account"
              class="mb-3"
              type="bank-account"
              required
            />
            <validation-error :errors="apiValidationErrors.bank_account" />

            <base-input
              v-if="vendor_toggle"
              :label="$t('userInvoiceCard.vatNumber')"
              :placeholder="$t('userInvoiceCard.provideValidVatNumber')"
              v-model="vendor.vat_number"
              name="VAT Number"
              class="mb-3"
              required
            />
            <validation-error :errors="apiValidationErrors.vat_number" />

            <base-input
              v-if="vendor_toggle"
              :label="$t('userInvoiceCard.companyName')"
              :placeholder="$t('userInvoiceCard.provideValidCompanyName')"
              v-model="vendor.company_name"
              name="Company Name"
              class="mb-3"
              required
            />
            <validation-error :errors="apiValidationErrors.company_name" />

            <base-input
              :label="$t('userInvoiceCard.fullAddress')"
              :placeholder="$t('userInvoiceCard.enterStreetAddressBuilding')"
              v-model="vendor.address"
              name="Address"
              class="mb-3"
              required
            />

            <validation-error :errors="apiValidationErrors.address" />

            <base-input
              :label="$t('userInvoiceCard.cityTown')"
              :placeholder="$t('userInvoiceCard.enterCity')"
              v-model="vendor.city"
              name="City"
              class="mb-3"
              required
            />

            <validation-error :errors="apiValidationErrors.city" />

            <base-input
              :label="$t('userInvoiceCard.stateCounty')"
              :placeholder="$t('userInvoiceCard.enterStateOrCounty')"
              v-model="vendor.state"
              name="State"
              class="mb-3"
            />
            <validation-error :errors="apiValidationErrors.state" />

            <base-input
              :label="$t('userInvoiceCard.postCode')"
              :placeholder="$t('userInvoiceCard.enterPostcode')"
              v-model="vendor.postal_code"
              name="Postcode"
              class="mb-3"
              required
            />
            <validation-error :errors="apiValidationErrors.postal_code" />

            <base-input
              :label="$t('userInvoiceCard.phoneNo')"
              :placeholder="$t('userInvoiceCard.enterPhoneNumber')"
              v-model="vendor.phone_number"
              name="Phone"
              class="mb-3"
              required
            />
            <validation-error :errors="apiValidationErrors.phone_number" />

            <base-input :label="$t('userInvoiceCard.country')">
              <el-select
                v-model="vendor.country"
                filterable
                :placeholder="$t('general.select')+'...'"
                required
                name="Country"
              >
                <el-option
                  v-for="country in getCountriesList()"
                  :key="country"
                  :label="country"
                  :value="country"
                >
                </el-option>
              </el-select>
            </base-input>
            <validation-error :errors="apiValidationErrors.country" />

            <div class="my-4">
              <base-button
                type="button"
                class="btn btn-sm btn-primary"
                native-type="submit"
              >
               {{$t('userInvoiceCard.updateInformation')}}
              </base-button>
            </div>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import moment from "moment-timezone";
import { isNil, isNull } from "lodash";

export default {
  name: "UserInvoiceInfos",
  components: {
    BaseButton,
    BaseInput,
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationError,
  },
  mixins: [formMixin],
  props: {
    user: Object,
  },
  data() {
    return {
      vendor: {
        type: "vendors",
        user_id: this.user.id,
        name: null,
        address: null,
        city: null,
        country: null,
        state: null,
        postal_code: null,
        vendor_type: "personal",
        currency: "AUD",
        bank_account: null,
        phone_number: null,
        account_number: null,
        routing_number: null,
        company_name: null,
        vat_number: null,
      },
      vendor_toggle: false,
    };
  },
  async created() {
    await this.init();
  },
  watch: {
    vendor: {
      handler: "isFromValid",
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async init() {
      const params = {
        include: "roles,vendors",
      };
      const id = this.user.id;
      await this.$store.dispatch("users/get", { id, params }).then(() => {
        const resp = this.$store.getters["users/user"];
        if (!_.isNil(resp.vendors)) {
          this.vendor = resp.vendors;
          this.vendor_toggle =
            this.vendor.vendor_type === "business" ? true : false;
        }
        console.log("Resp", _.isNil(resp.vendors));
      });
    },
    async submitForm() {
      try {
        let resp = null;
        if (_.isEmpty(this.vendor?.id)) {
          // Create a new vendor record
          this.vendor.vendor_type =
            this.vendor_toggle === false ? "personal" : "business";
          await this.$store.dispatch("vendors/add", this.vendor).then(() => {
            this.vendor = this.$store.getters["vendors/vendor"];

            delete this.vendor.status;
            delete this.vendor.links;

            this.$notify({
              type: "success",
              message:
                this.$t('userInvoiceCard.yourInvoiceInformationHasBeenCreatedSuccessfully'),
            });
          });
        } else {
          // Update the existing data
          this.vendor.vendor_type =
            this.vendor_toggle === false ? "personal" : "business";
          this.vendor.user_id = this.user.id;
          await this.$store.dispatch("vendors/update", this.vendor).then(() => {
            this.vendor = this.$store.getters["vendors/vendor"];

            delete this.vendor.status;
            delete this.vendor.links;

            this.$notify({
              type: "success",
              message:
                this.$t('userInvoiceCard.yourInvoiceInformationHasBeenUpdatedSuccessfully'),
            });
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong') + '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    isFromValid() {
      setTimeout(() => {
        if (this.$refs.user_invoice_form?.fields) {
          this.requiredFieldsMissing = [];
          for (const key in this.$refs.user_invoice_form.fields) {
            if (this.$refs.user_invoice_form.fields.hasOwnProperty(key)) {
              const item = this.$refs.user_invoice_form.fields[key];
              if (item.invalid && item.required) {
                this.requiredFieldsMissing.push(key);
              }
            }
          }
          if (!this.vendor.country) {
            this.requiredFieldsMissing.push("Country");
          }
          this.$emit("invoiceFormValid", this.requiredFieldsMissing);
        }
      }, 100);
    },
  },
};
</script>

<template>
  <side-bar>
    <template slot="links">
      <!-- Overview -->
      <sidebar-item
        opened
        :link="{
          name: $t('appSideNav.overview'),
          icon: 'fas fa-chart-bar text-primary',
        }"
      >
        <sidebar-item
          :link="{
            name: $t('appSideNav.dashboard'),
            path: '/manage/dashboard',
          }"
        />

        <sidebar-item
          :link="{
            name: $t('appSideNav.calendar'),
            path: '/manage/calendar',
          }"
        />

        <sidebar-item
          :link="{
            name: $t('appSideNav.collection'),
            path: '/manage/collections',
          }"
        />
      </sidebar-item>

      <!-- Events -->
      <sidebar-item
        :link="{
          name: $t('appSideNav.events'),
          icon: 'ni ni-calendar-grid-58 text-primary',
        }"
      >
        <sidebar-item
          :link="{
            name: $t('appSideNav.eventManagement'),
            path: '/manage/events/list-items',
          }"
        />

        <sidebar-item
          :link="{
            name: $t('appSideNav.tagManagement'),
            path: '/manage/tags/list-tags',
          }"
        />
        <sidebar-item
          v-if="isDeveloper()"
          :link="{
            name: $t('appSideNav.surveyManagement'),
            path: '/manage/survey/list-surveys',
          }"
        />
        <sidebar-item
          v-if="isDeveloper()"
          :link="{
            name: $t('appSideNav.speakerManagement'),
            path: '/manage/speakers/list-speakers',
          }"
        />
        <sidebar-item
          v-if="isDeveloper()"
          :link="{
            name: 'Sponsor Management',
            path: '/manage/sponsors/list-sponsors',
          }"
        />
      </sidebar-item>

      <!-- Attendees -->
      <sidebar-item
        true
        :link="{
          name: $t('appSideNav.attendees'),
          icon: 'fas fa-users text-primary',
        }"
      >
        <sidebar-item
          :link="{
            name: $t('appSideNav.guestManagement'),
            path: '/manage/attendees/list-attendees',
          }"
        />
        <sidebar-item
          v-if="isDeveloper()"
          :link="{
            name: $t('appSideNav.ticketManagement'),
            path: '/manage/attendees/list-tickets',
          }"
        />
      </sidebar-item>

      <!-- Bookings -->
      <sidebar-item
        true
        :link="{
          name: $t('appSideNav.bookings'),
          icon: 'ni ni-money-coins text-primary',
        }"
      >
        <sidebar-item
          :link="{
            name: $t('appSideNav.ticketManagement'),
            path: '/manage/tickets/list-tickets',
          }"
        />
      </sidebar-item>

      <!-- Reports -->
      <sidebar-item
        v-if="
          roles.includes('admin') ||
          roles.includes('developer') ||
          roles.includes('moderator')
        "
        :link="{
          name: $t('appSideNav.reports'),
          icon: 'fa fa-coins text-primary',
        }"
      >
        <sidebar-item
          :link="{
            name: $t('appSideNav.payoutManagement'),
            path: '/manage/reports/list-payouts',
          }"
        />
      </sidebar-item>
      <!-- Sponsors -->
      <!--      <sidebar-item-->
      <!--          true-->
      <!--          :link="{-->
      <!--            name: 'Features',-->
      <!--            icon: 'fa fa-duotone fa-layer-group text-primary',-->
      <!--          }"-->
      <!--      >-->

      <!--        <sidebar-item-->
      <!--            :link="{-->
      <!--              name: 'Sponsor Management',-->
      <!--              path: '#',-->
      <!--              // path: '/examples/user-management/list-users',-->
      <!--            }"-->
      <!--        />-->
      <!--      </sidebar-item>-->

      <!-- Venues -->
      <sidebar-item
        true
        :link="{
          name: $t('appSideNav.locations'),
          icon: 'ni ni-square-pin text-primary',
        }"
      >
        <sidebar-item
          :link="{
            name: $t('appSideNav.venueManagement'),
            path: '/manage/venues/list-venues',
            // path: '/examples/user-management/list-users',
          }"
        />
      </sidebar-item>

      <!-- Console -->
      <sidebar-item
        v-show="
          roles.includes('admin') ||
          roles.includes('developer') ||
          roles.includes('moderator')
        "
        :link="{
          name: $t('appSideNav.console'),
          icon: 'ni ni-badge text-danger',
        }"
      >
        <sidebar-item
          :link="{
            name: $t('appSideNav.userManagement'),
            path: '/console/users/list-users',
          }"
        />
        <sidebar-item
          v-if="
            roles.includes('admin') ||
            roles.includes('developer') ||
            roles.includes('moderator')
          "
          :link="{
            name: $t('appSideNav.categoryManagement'),
            path: '/console/categories/list-categories',
          }"
        />
        <sidebar-item
          :link="{
            name: $t('appSideNav.rolesManagement'),
            path: '/console/roles/list-roles',
          }"
        />
      </sidebar-item>

      <!-- Developers Component & Tools -->
      <sidebar-item
        v-show="roles.includes('developer')"
        :link="{
          name: 'Development Tools',
          icon: 'fab fa-vuejs',
        }"
      >
        <!-- Documentation -->
        <sidebar-item
          :link="{
            name: 'Documentation',
            target: '_blank',
            isRoute: true,
            path: 'https://vue-argon-dashboard-pro-laravel.creative-tim.com/documentation/',
          }"
          class="btn btn-sm btn-danger mr-4 ml-3 pl-0 mt-2"
        ></sidebar-item>

        <sidebar-item
          :link="{
            name: 'Examples',
            icon: 'ni ni-single-copy-04 text-pink',
          }"
          ><sidebar-item
            :link="{
              name: 'Main Dashboard',
              path: '/manage/dashboard',
            }"
          ></sidebar-item>

          <sidebar-item
            :link="{
              name: 'Alternative Dashboard',
              path: '/alternative',
            }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Pages',
            icon: 'ni ni-ungroup text-danger',
          }"
        >
          <sidebar-item :link="{ name: 'Pricing', path: '/pricing' }" />
          <sidebar-item :link="{ name: 'Lock', path: '/lock' }" />
          <sidebar-item :link="{ name: 'Timeline', path: '/pages/timeline' }" />
          <sidebar-item :link="{ name: 'Profile', path: '/pages/user' }" />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Components',
            icon: 'ni ni-ui-04 text-info',
          }"
        >
          <sidebar-item
            :link="{ name: 'Buttons', path: '/components/buttons' }"
          />
          <sidebar-item :link="{ name: 'Cards', path: '/components/cards' }" />
          <sidebar-item
            :link="{ name: 'Grid', path: '/components/grid-system' }"
          />
          <sidebar-item
            :link="{ name: 'Notifications', path: '/components/notifications' }"
          />
          <sidebar-item :link="{ name: 'Icons', path: '/components/icons' }" />
          <sidebar-item
            :link="{ name: 'Typography', path: '/components/typography' }"
          />

          <sidebar-item :link="{ name: 'Multi Level' }">
            <sidebar-item :link="{ name: 'Third level menu', path: '#!' }" />
            <sidebar-item :link="{ name: 'Just another link', path: '#a' }" />
            <sidebar-item :link="{ name: 'One last link', path: '#b' }" />
          </sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Forms',
            icon: 'ni ni-single-copy-04 text-pink',
          }"
        >
          <sidebar-item :link="{ name: 'Elements', path: '/forms/elements' }" />
          <sidebar-item
            :link="{ name: 'Components', path: '/forms/components' }"
          />
          <sidebar-item
            :link="{ name: 'Validation', path: '/forms/validation' }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Tables',
            icon: 'ni ni-align-left-2 text-default',
          }"
        >
          <sidebar-item :link="{ name: 'Tables', path: '/tables/regular' }" />
          <sidebar-item
            :link="{ name: 'Sortable', path: '/tables/sortable' }"
          />
          <sidebar-item
            :link="{ name: 'Paginated Tables', path: '/tables/paginated' }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Maps',
            icon: 'ni ni-map-big text-primary',
          }"
        >
          <sidebar-item :link="{ name: 'Google', path: '/maps/google' }" />
          <sidebar-item :link="{ name: 'Vector', path: '/maps/vector' }" />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Widgets',
            icon: 'ni ni-archive-2 text-green',
            path: '/widgets',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Charts',
            icon: 'ni ni-chart-pie-35 text-info',
            path: '/charts',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Calendar',
            icon: 'ni ni-calendar-grid-58 text-red',
            path: '/calendar',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="isDeveloper()"
          :link="{
            name: 'Generate Stripe Coupons',
            icon: 'ni ni-archive-2 text-green',
            path: '/generate-stripe-coupons',
          }"
        >
        </sidebar-item>
      </sidebar-item>

      <hr
        class="my-3"
        style="
          border-color: rgba(0, 0, 0, 0.1) currentcolor currentcolor;
          border-style: solid none none;
          border-width: 1px 0px 0px;
          border-image: none 100% / 1 / 0 stretch;
          min-width: 80%;
          overflow: visible;
          box-sizing: content-box;
          height: 0px;
        "
      />

      <!-- Buttons -->
      <sidebar-item
        id="cta-create-event"
        :link="{
          name: $t('appSideNav.createEvent'),
          icon: 'ni ni-fat-add mr-3 pb-1',
          target: '_self',
          isRoute: true,
          path: '/manage/events/add-item',
        }"
        class="btn btn-danger bg-gradient-danger"
        style="border-radius: 20px 20px 0 20px; margin-left: 20px; margin-right: 20px;"
        data-testid="cta-create-event"
      ></sidebar-item>

      <hr
        class="my-3"
        style="
          border-color: rgba(0, 0, 0, 0.1) currentcolor currentcolor;
          border-style: solid none none;
          border-width: 1px 0px 0px;
          border-image: none 100% / 1 / 0 stretch;
          min-width: 80%;
          overflow: visible;
          box-sizing: content-box;
          height: 0px;
        "
      />

      <!-- Promo Boxes -->
      <user-promo-plan
        class="ml-3 mr-3 align-content-center"
        :role="roles[0]"
      ></user-promo-plan>
    </template>
  </side-bar>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import UserPromoPlan from "@/views/Promo/UserUpgrade/UserPromoPlan.vue";

export default {
  props: {
    roles: {
      type: Array,
      description: "Role to build the Navigation blocks based on Permissions",
      required: true,
    },
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    FadeTransition,
    UserPromoPlan,
  },
};
</script>

<style>
.navbar-nav .nav-item {
  & .btn-danger {

  }
}
</style>

<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'bg-success navbar-dark': navType === 'default' }"
    type="base"
  >
    <!--  Notification Center-->
<!--        <base-dropdown-->
<!--            class="nav-item"-->
<!--            tag="li"-->
<!--            title-classes="nav-link"-->
<!--            title-tag="a"-->
<!--            icon="fas fa-tasks"-->
<!--            menu-classes="dropdown-menu-xl dropdown-menu-left py-0 overflow-hidden"-->
<!--        >-->
<!--        </base-dropdown>-->

    <!--  Task List Widget-->
    <el-dropdown
      type="primary"
      trigger="click"
      :hide-on-click="false"
      :placement="dropdown.placement"
      menu-classes="dropdown-menu-xl dropdown-menu-left py-0 overflow-hidden"
    >
      <span style="color: #f1f1f1">
        <i class="fas fa-tasks ml-1" style="color: #f1f1f1" />
        {{ $t("general.notes", { msg: "hello" }) }}
      </span>
      <el-dropdown-menu slot="dropdown" class="task-dropdown">
        <template>
          <task-list></task-list>
        </template>
      </el-dropdown-menu>
    </el-dropdown>

    <!-- Navbar links -->
    <ul class="navbar-nav align-items-left ml-md-auto"></ul>

    <!-- Menu - Mobile Toggle-->
    <ul class="navbar-nav align-items-center ml-auto ml-md-0 mr-2">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': navType === 'default',
            'sidenav-toggler-light': navType === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>

      <!-- My Account-->
      <base-dropdown
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <a href="#" class="nav-link pr-0" @click.stop slot="title-container">
          <div class="media align-items-center mr-2 ml--3">
            <span
              class="avatar avatar-sm rounded-circle avatar-image"
              :style="{
                'background-image': `url('${profileImage}')`,
              }"
            >
            </span>
          </div>
        </a>

        <!--        <template>-->
        <!--          <div class="dropdown-divider"></div>-->
        <!--          <a @click.prevent="logout()" to="" class="dropdown-item">-->
        <!--            <i class="ni ni-user-run"></i>-->
        <!--            <span>Logout</span>-->
        <!--          </a>-->
        <!--        </template>-->
      </base-dropdown>

      <!-- My Account-->
      <el-dropdown
        trigger="click"
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <span class="el-dropdown-link">
          <span class="mb-0 text-sm text-white font-weight-bold">{{
            getInitials(title)
          }}</span>
          <i class="el-icon-arrow-down el-icon--right text-white text-" />
        </span>
        <el-dropdown-menu v-slot="dropdown">
          <el-dropdown-item class="mr-2">
            <span class="dropdown-header noti-title" @click.stop>
              <h6 class="text-overflow m-0">
                <i
                  id="user-premium-label"
                  class="fas fa-crown text-orange mr-2"
                  v-if="isPremiumUser()"
                />
                <i
                  id="user-freemium-label"
                  class="fas fa-user-astronaut text-base mr-2"
                  v-else-if="isFreeUser()"
                />
                <i
                  id="admins-user-label"
                  class="ni ni-badge text-danger mr-2"
                  v-else
                />
                {{$t("appNavbar.welcomeCreator")}}
              </h6>
            </span>
          </el-dropdown-item>

          <el-dropdown-item divided>
            <div class="text-left" @click="goToDashboard()">
              <span> <i class="ni ni-bold-left mr-3" /> {{$t("appNavbar.dashboard")}} </span>
            </div>
          </el-dropdown-item>
          <el-dropdown-item divided>
            <div class="text-left" @click="goToProfile()">
              <span> <i class="ni ni-circle-08 mr-3" /> {{$t("appNavbar.myProfile")}} </span>
            </div>
          </el-dropdown-item>
          <el-dropdown-item>
            <div class="text-left" @click="goToTickets()">
              <span> <i class="fa fa-ticket-alt mr-3" /> {{$t("appNavbar.myTickets")}}</span>
            </div>
          </el-dropdown-item>
          <el-dropdown-item v-if="!isFreeUser()">
            <div class="text-left" @click="goToScanEvent()">
              <span> <i class="fa fa-qrcode mr-3" /> {{$t("appNavbar.scanTickets")}}</span>
            </div>
          </el-dropdown-item>
          <el-dropdown-item divided>
            <div class="text-left" @click="logout()">
              <span> <i class="ni ni-user-run mr-3" /> {{$t("appNavbar.logOut")}}</span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </ul>

    <!-- Language Switch-->
    <base-select-language v-if="isDeveloper()"></base-select-language>

  </base-nav>
</template>
<script>
import TaskList from "@/views/Tasks/TaskList.vue";
import ExampleTaskList from "@/views/Widgets/ExampleTaskList.vue";

import { BaseNav, BaseSelectLanguage } from "@/components";
import { Dropdown, DropdownItem, DropdownMenu } from "element-ui";

export default {
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    BaseNav,
    TaskList,
    BaseSelectLanguage,
  },
  props: {
    navType: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  async created() {
    this.$store.watch(
      () => this.$store.getters["profile/me"],
      (me) => {
        this.title = me.name;
        this.avatar = me.profile_image;
      }
    );
    // await this.$store.dispatch("profile/me");
    const initialUser = await { ...this.$store.getters["profile/me"] };
    this.title = initialUser.name;
    this.avatar = initialUser.profile_image;
  },
  beforeDestroy() {
    document.removeEventListener("NewProfileImage", this.avatarFunc);
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    profileImage() {
      document.addEventListener("NewProfileImage", this.avatarFunc, false);
      return this.avatar ? this.avatar : "/img/placeholder.jpg";
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      title: "Profile",
      avatar: null,
      dropdown: {
        hideOnClick: false,
        placement: "top-start",
      },
    };
  },
  methods: {
    avatarFunc(e) {
      this.avatar = e.detail;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    getInitials(name) {
      const words = name.split(" ");
      const initials = words.map((word) => word.charAt(0));

      return initials.join("");
    },
    goToProfile() {
      // Redirect to user profile page
      this.$router.push({ name: "User Profile" });
    },
    goToTickets() {
      // Redirect to user ticket page
      this.$router.push({ path: "/manage/attendees/list-tickets" });
    },
    goToDashboard() {
      // Redirect to user profile page
      this.$router.push({ name: "Main Dashboard" });
    },
    goToScanEvent() {
      // Redirect to scan event page
      this.$router.push({ name: "Scan Event" });
    },
    async logout() {
      try {
        // clear store and logout
        this.$store.dispatch("logout");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('appNavbar.oopsSomethingWentWrongWhileYouLogOut')+'!',
        });
      }
    },
  },
};
</script>

<style>
.btn-rotate.dropdown-toggle.nav-link {
  color: #fff;
}
</style>

<template>
  <div class="container-fluid mt-3">
    <div class="help-container mb-1">
      <el-drawer
        title="I am the title"
        :visible.sync="drawer"
        :with-header="false"
      >
        <h4 class="mt-5 mb-3">Visual Guide:</h4>
        <vue-plyr>
          <div data-plyr-provider="youtube" data-plyr-embed-id="-B58GgsehKQ" />
        </vue-plyr>
        <hr />
        <h4>Quick Guide:</h4>
        <ul>
          <li>Follow BookMeInScotty on: <a href="#">Twitter</a>.</li>
          <li>
            Read and subscribe to our newsletter
            <a href="#">Book Me In Scotty Blog</a>.
          </li>
          <li>Follow Book Me In Scotty on <a href="#">Instagram</a>.</li>
          <li>
            Follow Me In Scotty on
            <a href="https://www.facebook.com/creativetim">Facebook</a>.
          </li>
        </ul>
        <hr />

        <h4>Help Center:</h4>
        <a href="#">Read the official documentation here</a>
      </el-drawer>
    </div>
    <div id="event-list-card">
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template v-slot:header>
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">
                {{ $t("listEventItems.myEventsList") }}
<!--            <i class="fa fa-info-circle pointer-class" @click="drawer = true"/>-->
              </h3>

              <small class="text-muted">
              {{$t("general.showing")}} {{ to }} {{$t("general.of")}} {{ total }} {{$t("general.results")}}

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                </span>
              </small>
            </div>

            <div class="col-6 text-right">
              <base-button type="primary" icon size="sm" @click="addItem()">
                <span class="btn-inner--icon"
                  ><i class="ni ni-fat-add"></i
                ></span>
                <span class="btn-inner--text">{{ $t("listEventItems.newEvent") }}</span>
              </base-button>
            </div>
          </div>
        </template>

        <template>
          <div id="event-list-table">
            <div class="d-flex justify-content-center flex-wrap">
              <div class="col-10">
                <base-input
                  v-model="query"
                  type="search"
                  prepend-icon="fas fa-search"
                  :placeholder="$t('listEventItems.typeNameToSearch')"
                  clearable
                  :class="{
                    'filter-opened': showFilter,
                  }"
                >
                  <small slot="append" class="font-weight-bold">
                    <div @click="showFilter = !showFilter">
                      <span class="filter-icon">
                        <i class="fa fa-filter" />
                      </span>
                    </div>
                  </small>
                </base-input>
              </div>

              <div class="col-md-10 filters-container mb-5" v-show="showFilter">
                <div class="row">
                  <div class="col-md-3 offset-md-1">
                    <base-input label="Filter by Month">
                      <flat-picker
                        slot-scope="{ blur }"
                        @on-open="customFocus(0)"
                        @on-close="blur"
                        :config="{
                          allowInput: true,
                          altInput: true,
                          plugins: plugins,
                        }"
                        class="form-control custom-form-radius-left datepicker"
                        placeholder="Click to open"
                        v-model="month"
                      >
                      </flat-picker>
                      <small slot="append" class="font-weight-bold">
                        <div @click="month = null">
                          <span style="color: #adb5bd; cursor: pointer">
                            <i class="fa fa-times-circle" />
                          </span></div
                      ></small>
                    </base-input>
                  </div>

                  <div class="col-md-3">
                    <base-input label="Filter by Type">
                      <el-select
                        v-model="eventType"
                        filterable
                        clearable
                        prepend-icon="fas fa-user"
                        placeholder="Select..."
                      >
                        <el-option
                          v-for="eventType in allEventTypes"
                          :key="eventType"
                          :label="eventType"
                          :value="eventType"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-3">
                    <base-input label="When is Happening">
                      <el-select
                        v-model="eventStatus"
                        filterable
                        clearable
                        prepend-icon="fas fa-user"
                        placeholder="Select..."
                      >
                        <el-option
                          v-for="eventStatus in allEventStatus"
                          :key="eventStatus"
                          :label="eventStatus"
                          :value="eventStatus"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-2 text-right filter-button">
                    <base-button
                      type="primary"
                      size="md"
                      class="btn-base btn-icon"
                      @click="fetchList()"
                    >
                      <span class="btn-inner--text">Filter</span>
                    </base-button>
                  </div>
                </div>
              </div>
            </div>

            <el-table
              class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="items"
              @sort-change="sortChange"
            >
              <!-- Load Spinner -->
              <div slot="empty" v-if="loading">
                <img
                  src="/img/loading.gif"
                  style="height: 100px; width: 100px"
                />
              </div>

              <el-table-column
                label="ID"
                prop="id"
                sortable="custom"
                min-width="95px"
                align="center"
                v-if="isSuperUser()"
              />

            <el-table-column :label="$t('listEventItems.name')"
              prop="name"
              sortable="custom"
              min-width="260px"
              max-width="260px"
              align="left"
            >
              <template v-slot="{ row }">
                <a
                    type="text"
                    @click="viewItem(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <strong class="text-black-50">{{ row.name }}</strong>
                  </a>
                </template>
              </el-table-column>

            <el-table-column :label="$t('listEventItems.type')"
                             prop="event_type"
                             min-width="120px"
                             align="left">
              <template v-slot="{ row }">
                <badge class="badge-dot mr-4" pill>
                  <i :class="`bg-${getItemType(row.event_type)}`"></i>
                  <span class="status text-black-50 text-break">
                    {{ getEventFullStatusTypes(row.event_type) }}
                  </span>
                </badge>
              </template>
            </el-table-column>

            <el-table-column :label="$t('general.organizer')"
                             prop="organizer"
                             sortable="custom"
                             min-width="155px"
                             align="left"
                             class="text-break"
            v-if="isSuperUser()"/>

            <el-table-column :label="$t('listEventItems.hero')"
                             prop="hero_image"
                             min-width="140px"
                             max-width="150px"
                             align="left">

              <template v-slot="{ row }">
                <img
                  v-if="row.hero_image"
                  :src="getHeroImage(row.hero_image)"
                  style="width: 100px; height: auto"
                  class="hero hero-thumbnail"
                  alt="placeholder"
                />
              </template>
            </el-table-column>

            <el-table-column :label="$t('listEventItems.attendees')"
                             prop="total_attendees"
                             min-width="145px"
                             align="left"
            >
              <template v-slot="{ row }">
                <div class="avatar-group text-center">
                  <a
                      v-if="row.total_attendees >= 3"
                      @click="goToGuestList(row)"
                      href="#"
                      class="avatar avatar-sm rounded-circle"
                      data-toggle="tooltip"
                      data-original-title="Ryan Tompson"
                    >
                      <img
                        alt="Image placeholder"
                        src="/img/theme/team-1.jpg"
                      />
                    </a>
                    <a
                      v-if="row.total_attendees >= 2"
                      @click="goToGuestList(row)"
                      href="#"
                      class="avatar avatar-sm rounded-circle"
                      data-toggle="tooltip"
                      data-original-title="Romina Hadid"
                    >
                      <img
                        alt="Image placeholder"
                        src="/img/theme/team-2.jpg"
                      />
                    </a>
                    <a
                      v-if="row.total_attendees >= 1"
                      @click="goToGuestList(row)"
                      href="#"
                      class="avatar avatar-sm rounded-circle"
                      data-toggle="tooltip"
                      data-original-title="Alexander Smith"
                    >
                      <img
                        alt="Image placeholder"
                        src="/img/theme/team-3.jpg"
                      />
                    </a>
                    <a
                      v-if="row.total_attendees > 3"
                      @click="goToGuestList(row)"
                      href="#"
                      class="avatar avatar-sm rounded-circle"
                      style="bottom: 10px"
                      data-toggle="tooltip"
                      data-original-title="more"
                    >
                      {{ getAttendeesCount(row) }}
                    </a>

                    <a
                      v-else-if="row.total_attendees === 0"
                      @click="goToGuestList(row)"
                      href="#"
                      class="avatar avatar-sm rounded-circle"
                      data-toggle="tooltip"
                      data-original-title="more"
                    >
                      {{ getAttendeesCount(row) }}
                    </a>
                  </div>
                </template>
              </el-table-column>

            <el-table-column
              :label="$t('listEventItems.capacity')"
              prop="total_capacity"
              min-width="170x"
              align="left"
            >
              <template v-slot="{ row }">
                <div class="ml-1">
                  <!--                  <span class="completion mr-2">{{ getCapacityPercentage() }}%</span>-->
                    <base-progress
                      :show-percentage="getPercentage(row)"
                      :value="getCapacityPercentage(row)"
                      :show-label="true"
                      :label="getCapacityTotal(row)"
                      class="pt-0"
                      type="primary"
                      :height="7"
                      :animated="true"
                    />
                  </div>
                </template>
              </el-table-column>

            <el-table-column :label="$t('listEventItems.happening')"
              prop="start_at"
              sortable="custom"
              min-width="165px"
              align="left"
            >
              <template v-slot="{ row }">
                <div class="ml-2">
                  <badge rounded :type="happeningTime(row).label">
                    <span class="status">{{
                      happeningTime(row).date
                    }}</span>
                  </badge>
                </div>
              </template>
            </el-table-column>

            <el-table-column :label="$t('general.when')"
                             min-width="115px"
                             align="left">
              <template v-slot="{ row }">
                <div class="ml-2">
                  <small class="text-muted text-uppercase text-black-60 text-wrap">
                    {{ eventWhenIsTime(row.start_at) }}
                  </small>
                </div>
              </template>
            </el-table-column>

            <el-table-column :label="$t('listEventItems.actions')"
                             min-width="125px"
                             align="left">
              <template v-slot="scope">
                <div class="table-actions ml-2">
                  <el-tooltip :content="$t('general.previewEvent')" placement="top">
                    <a
                        type="text"
                        @click="viewItem(scope.row)"
                        class="table-action"
                        data-toggle="tooltip"
                        style="cursor: pointer"
                      >
                        <i class="far fa-eye text-base"></i>
                      </a>
                    </el-tooltip>

                  <el-tooltip :content="$t('listEventItems.editEvent')" placement="top">
                    <a
                        type="text"
                        @click="editItem(scope.row)"
                        class="table-action"
                        data-toggle="tooltip"
                        style="cursor: pointer"
                      >
                        <i class="far fa-edit text-base"></i>
                      </a>
                    </el-tooltip>

                  <el-tooltip :content="$t('listEventItems.moreOptions')" placement="top">
                    <el-dropdown trigger="click" class="dropdown">
                    <span class="text-light ml-2">
                      <i class="fa fa-ellipsis-v text-base mt-2"/>
                    </span>
                      <el-dropdown-menu
                          class="dropdown-menu dropdown-menu-arrow show text-center ml-3 mr-3"
                          slot="dropdown"
                      >

                        <div style="display: flex; flex-direction: column">
                          <base-button type="button"
                                       class="btn btn-sm btn-base ml-2 mb-2"
                                       @click="onPublicLinkCopy(scope.row)"
                                       data-toggle="tooltip"
                                       style="cursor: pointer"
                                       append
                          >
                            <i class="fa fa-link" /> {{$t("listEventItems.publicLink")}}
                          </base-button>

                            <base-premium-overlay
                              :enabled="getUserRole() === 'creator'"
                              badge-label="Duplicate event"
                            ></base-premium-overlay>

                          <base-button type="button"
                                       class="btn btn-sm btn-base ml-2 mb-2"
                                       :disabled="getUserRole() === 'creator'"
                                       @click="cloneItem(scope.row)"
                                       data-toggle="tooltip"
                                       style="cursor: pointer"
                                       append
                          >
                            <i class="far fa-copy"/>
                            {{$t("listEventItems.duplicateEvent")}}
                          </base-button>

                          <base-button type="button"
                                       class="btn btn-sm btn-danger ml-2 mt-2"
                                       @click="deleteItem(scope.row.id)"
                                       data-toggle="tooltip"
                                       style="cursor: pointer">
                            <i class="far fa-trash-alt"/> {{$t("general.delete")}}
                          </base-button>
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>

          </el-table>
        </div>
        </template>

        <template v-slot:footer>
          <div
            id="event-list-footer"
            class="row d-flex justify-content-md-between justify-content-center flex-wrap"
          >
            <div class="col-auto">
              <el-select
                class="select-primary pagination-select mb-1"
                style="width: 140px"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary mt-1"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-auto">
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              />
            </div>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Drawer,
} from "element-ui";
import { BasePagination } from "@/components/";
import BasePremiumOverlay from "@/components/Premium/BasePremiumOverlay.vue";
import flatPicker from "vue-flatpickr-component";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
require("flatpickr/dist/plugins/monthSelect/style.css");
require("flatpickr/dist/flatpickr.css");
import swal from "sweetalert2";
import moment from "moment";
import _ from "lodash";

export default {
  layout: "ListEventItems",
  components: {
    BasePagination,
    BasePremiumOverlay,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Drawer.name]: Drawer,
    ["flatPicker"]: flatPicker,
  },
  data() {
    return {
      drawer: false,
      query: null,
      selectedRows: [],
      sort: "-start_at",
      total: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      dropdown: {
        hideOnClick: true,
        placement: "bottom-end",
      },
      items: [],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      loading: true,
      lodash_: _,
      showFilter: false,
      allEventTypes: ["physical", "virtual", "hybrid", "tba"],
      allEventStatus: ["All Events", "Upcoming Events"],
      eventType: null,
      eventStatus: "Upcoming Events",
      month: null,
      plugins: [
        new monthSelectPlugin({
          // shorthand: true, //defaults to false
          // dateFormat: "F Y", //defaults to "F Y"
          // altFormat: "F Y", //defaults to "F Y"
          // theme: "light" // defaults to "light"
        }),
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters["profile/me"];
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    query: {
      handler: "fetchListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "fetchList",
      immediate: false,
      deep: true,
    },
  },
  methods: {
    fetchListDebounced: _.debounce(function () {
      this.fetchList();
    }, 300),
    async fetchList() {
      let start_at;
      let end_at;
      if (this.month) {
        let tempDate = this.getDateFormat(this.month);
        start_at = moment(tempDate)
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00");
        end_at = moment(tempDate).endOf("month").format("YYYY-MM-DD 23:59:59");
      }
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.user ? { user_id: this.user.id } : { user_id: 0 }),
            ...(this.query ? { name: this.query } : {}),
            ...(this.eventStatus === "Upcoming Events"
              ? { upcoming_at: 1 }
              : {}),
            ...(start_at && end_at
              ? { start_at: [start_at, end_at] }
              : { start_at: [] }),
            ...(this.eventType ? { event_type: this.eventType } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "category,tags",
        };

        // fire a GET request to load the items
        await this.$store.dispatch("items/list", params).then(() => {
          this.items = this.$store.getters["items/list"];
          this.total = this.$store.getters["items/listTotal"];
        });

        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong') + '!',
        });
      }
    },
    async deleteItem(id) {
      // Exclude Items from Delete

      // if (["1", "2"].includes(id)) {
      //   this.$notify({
      //     type: "danger",
      //     message: this.$t('listEventItems.youAreNotAllowerNotChangeDataOfDefaultItems')+'.',
      //   });
      //   return;
      // }

      const confirmation = await swal.fire({
        title: this.$t('listEventItems.deleteThisItem'),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t('listEventItems.yesDeleteIt'),
        cancelButtonText: this.$t('listEventItems.noKeepIt'),
        confirmButtonClass: "btn btn-warning",
        cancelButtonClass: "btn btn-primary",
      });

      try {
        if (confirmation.value === true) {
          // if (this.user.roles.name === "member") {
          //   this.$notify({
          //     type: "danger",
          //     message: this.$t('listEventItems.oopsYouAreNotAuthorizedToDoThisAction')+'.',
          //   });
          //   return;
          // }
          // send DELETE request to remove the Event
          await this.$store.dispatch("items/destroy", id).then(
            () => this.fetchList() // fetch to refresh the remaining Item list
          );

          this.$notify({
            type: "success",
            message:this.$t('listEventItems.eventDeletedSuccessfully'),
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong') + '!',
        });
      }
    },
    addItem() {
      this.$router.push({ name: "Add Event" });
    },
    viewItem(row) {
      this.$router.push({
        name: "Event Preview",
        params: { id: row.id },
      });
    },
    editItem(row) {
      this.$router.push({
        name: "Edit Event",
        params: { id: row.id },
      });
    },
    cloneItem(row) {
      this.$router.push({
        name: "Edit Event",
        params: { id: row.id },
        query: { clone: "true" },
      });
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.fetchList();
    },
    getItemType(row) {
      let status;
      switch (row) {
        case "virtual":
        case "hybrid":
          status = "primary";
          break;
        case "physical":
          status = "success";
          break;
        case "tba":
          status = "yellow";
          break;
      }
      return status;
    },
    getItemTypeLabel(row) {
      return row.event_type === "tba"
        ? _.upperCase(row.event_type)
        : _.upperFirst(row.event_type);
    },
    getHeroImage(hero) {
      if (hero.includes("http://") || hero.includes("https://")) {
        return hero;
      }
      // return cloud url
      return process.env.VUE_APP_AWS_S3_URL + hero;
    },
    getPercentage(row) {
      return row.total_capacity === -1 ? false : true;
    },
    getCapacityTotal(row) {
      return (
        row.total_attendees +
        "/" +
        (row.total_capacity === -1 ? " -" : row.total_capacity)
      );
    },
    getCapacityPercentage(row) {
      return Math.round((row.total_attendees / row.total_capacity) * 100);
    },
    getAttendeesCount(row) {
      return row.total_attendees > 3
        ? "+" + (row.total_attendees - 3)
        : row.total_attendees;
    },
    goToGuestList(row) {
      // Redirect to preview
      this.$router.push({
        name: "List Guests",
        // params: { id: this.item.id },
        query: { item: row.id },
      });
    },
    getCalendarFile() {
      this.$ics.removeAllEvents();
      this.$ics.addEvent(
        navigator.language === "zz-ZZ" ? "en-AU" : navigator.language,
        this.item.name,
        this.item.description,
        this.item.venues ? this.item.venues.address_name : "",
        this.item.start_at,
        this.item.end_at,
        this.eventPublicURL,
        {},
        "",
        ""
      );
      this.$ics.download(this.item.name);
    },
    happeningTime(eventDate) {
      // Client-User DateΤimezone
      const currentTimezone = this.user.timezone ?? this.timezone;
      const today = moment().tz(currentTimezone);

      // Event DateTimezone
      const originalDTZ = moment.tz(eventDate.start_at, eventDate.timezone);
      const happening = originalDTZ.clone().tz(currentTimezone);

      return {
        date: happening.fromNow(),
        label: today.diff(happening, "hours") < 0 ? "success" : "warning",
      };
    },
    eventWhenIsTime(startAt) {
      return moment(startAt).format("ddd, DD MMM  @ hh:ss A");
    },
    eventPublicURL(item) {
      const itemId = _.isEmpty(item.hash_code) ? item.id : item.hash_code;
      return process.env.VUE_APP_BASE_URL + "/event/" + itemId;
    },
    copyPublicLink(item) {
        try {
          swal.fire({
           title:this.$t('listEventItems.copiedPublicEventLink'),
          html:
            this.$t('listEventItems.youCanUseThisLinkToShareItWithWideGroup') + "<br>"  + "</hr>"  +
              "<br><b>" + this.$t('listEventItems.publicEventLink')+ ":</b><br> <button type='button' class='btn-icon-clipboard text-center'>" +
              this.eventPublicURL() +
              "</button> <br></hr>",
            type: "success",
            confirmButtonClass: "btn btn-outline-danger btn-fill",
            confirmButtonText: this.$t('general.close'),
            buttonsStyling: false,
            icon: "success",
          });
        } catch ($e) {
          alert("Cannot copy");
        }

    },
    onPublicLinkCopy(item) {
      const publicURL = this.eventPublicURL(item);
       navigator.clipboard.writeText(publicURL);
        this.$notify({
          type: 'success',
          message: this.$t('general.linkCopied') + '-' + publicURL
        })
      },
    filterAllItems() {
      this.filter.upcomingItems = !this.filter.upcomingItems;
      this.fetchList();
    },
    getDateFormat(month) {
      let tempMonth = month.substring(0, month.length - 4);
      let tempYear = month.substring(month.length - 4, month.length);
      return new Date(Date.parse(tempMonth + "1, " + tempYear));
    },
  },
};
</script>

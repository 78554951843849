<template>
  <div class="container-fluid mt-3">
    <back-button @click="goBack" />

    <div class="row mt-3">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{$t("addCategoryPage.addCategory")}}</h3>
            </div>
            <!-- <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
                >Back to list</base-button
              >
            </div> -->
          </div>
          <div class="card-body">
            <form ref="category_form" @submit.prevent="handleSubmit">
              <base-input
                :label="$t('addCategoryPage.name')"
                prepend-icon="fas fa-user"
                v-model="category.name"
              />
              <validation-error :errors="apiValidationErrors.name" />
              <base-input
                :label="$t('addCategoryPage.description')"
                prepend-icon="fas fa-user"
                v-model="category.description"
              />
              <validation-error :errors="apiValidationErrors.description" />
              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                 {{$t("addCategoryPage.addCategory")}}
                </base-button>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BackButton from "@/components/BackButton.vue";

export default {
  layout: "DashboardLayout",

  mixins: [formMixin],

  components: {
    ValidationError,
    BackButton,
  },

  data() {
    return {
      category: {
        type: "categories",
        name: null,
        description: null,
      },
    };
  },

  methods: {
    goBack() {
      this.$router.push({ name: "List Categories" });
    },

    async handleSubmit() {
      try {
        await this.$store.dispatch("categories/add", this.category);

        this.$notify({
          type: "success",
          message: this.$t('addCategoryPage.categoryAddedSuccessfully') + '.',
        });
        this.goBack();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong') + '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>

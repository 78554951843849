<template>
  <div class="row">
    <div class="col-md-6">
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col-8">
            <p class="mb-0">{{ $t("editEvents.eventResources") }}</p>
          </div>
        </div>

        <div class="card-body">
          <base-premium-overlay
            :enabled="!isDeveloper()"
            badge-label="Coming Soon"
            badge-style="warning"
            badge-message="This feature will be released soon, feature is under development"
          >
            <base-input label="Select speakers for this event">
              <el-select
                v-model="speakers"
                @change="setSelectedSpeakers()"
                multiple
                placeholder="Select..."
              >
                <el-option
                  v-for="single_speaker in all_speakers"
                  :key="single_speaker.id"
                  :value="single_speaker.id"
                  :label="
                    single_speaker.first_name + ' ' + single_speaker.last_name
                  "
                >
                </el-option>
              </el-select>
            </base-input>

            <el-collapse accordion>
              <el-collapse-item name="1">
                <template slot="title">
                  <div class="d-flex w-100 justify-content-between">
                    <div>
                      <div class="d-flex w-100 align-items-center">
                        <h4 class="mb-1" style="margin-top: 0.75rem">
                          Selected Speaker
                        </h4>
                      </div>
                    </div>
                    <small class="text-black-60"> Expand </small>
                  </div>
                </template>
                <div
                  class="col-md-6"
                  v-for="(speaker, index) in selected_speakers"
                  :key="index"
                >
                  <card class="border-bottom">
                    <div slot="header" class="row align-items-center">
                      <div class="col-8">
                        <div class="card-body">
                          <div
                            class="avatar avatar-xxl rounded-circle"
                            data-toggle="tooltip"
                            data-original-title="Alexander Smith"
                          >
                            <img
                              alt="Image placeholder"
                              src="/img/theme/team-3.jpg"
                            />
                          </div>
                          <div class="ml-3">
                            <h2 class="text-left text-base">
                              {{ speaker.first_name }} {{ speaker.last_name }}
                            </h2>
                            <h4>{{ speaker.expertise }}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </card>
                </div>
              </el-collapse-item>
            </el-collapse>
          </base-premium-overlay>
        </div>
      </card>
    </div>

    <div class="col-md-6">
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col-8">
            <p class="mb-0">Event Sponsors</p>
          </div>
        </div>

        <div class="card-body">
          <base-premium-overlay
            :enabled="!isDeveloper()"
            badge-label="Coming Soon"
            badge-style="warning"
            badge-message="This feature will be released soon, feature is under development"
          >
            <base-input label="Select sponsors for this event">
              <el-select
                v-model="sponsors"
                @change="setSelectedSponsors()"
                multiple
                placeholder="Select..."
              >
                <el-option
                  v-for="single_sponsor in all_sponsors"
                  :key="single_sponsor.id"
                  :value="single_sponsor.id"
                  :label="single_sponsor.name"
                >
                </el-option>
              </el-select>
            </base-input>

            <el-collapse accordion>
              <el-collapse-item name="1">
                <template slot="title">
                  <div class="d-flex w-100 justify-content-between">
                    <div>
                      <div class="d-flex w-100 align-items-center">
                        <h4 class="mb-1" style="margin-top: 0.75rem">
                          Selected Sponsor
                        </h4>
                      </div>
                    </div>
                    <small class="text-black-60"> Expand </small>
                  </div>
                </template>
                <div
                  class="col-md-8"
                  v-for="(sponsor, id) in selected_sponsors"
                  :key="sponsor.id"
                >
                  <card>
                    <div class="row align-items">
                      <template>
                        <div class="col-auto mt-1">
                          <img
                            alt="Image placeholder"
                            img-left
                            class="avatar avatar-xxl rounded-circle mb-3"
                            data-toggle="tooltip"
                            data-original-title="Alexander Smith"
                            :src="getSponsorLogo(sponsor?.logo)"
                          />

                          <h2 class="text-center text-base">
                            {{ sponsor.name }}
                          </h2>
                        </div>
                      </template>
                      <div class="col ml-3">
                        <span class="d-block h3">
                          {{ sponsor.summary }}
                        </span>
                      </div>
                    </div>
                  </card>
                </div>
              </el-collapse-item>
            </el-collapse>
          </base-premium-overlay>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import BasePremiumOverlay from "@/components/Premium/BasePremiumOverlay.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import formMixin from "@/mixins/form-mixin";

import {
  Select,
  Option,
  Tabs,
  TabPane,
  Collapse,
  CollapseItem,
  Timeline,
  TimelineItem,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
} from "element-ui";

export default {
  name: "edit-event-resources",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    BasePremiumOverlay,
    BaseInput,
  },
  mixins: [formMixin],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    activeName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      all_speakers: [],
      selected_speakers: [],
      speakers: [],
      all_sponsors: [],
      selected_sponsors: [],
      sponsors: [],
      cloneState: false,
    };
  },
  created() {
    // Data
    this.getSpeakers();
    this.getSponsors();
    // Props
    this.cloneState = this.$route.query.clone === "true";
    // Prevents attachments for Trix WYSIWYG Editor
    document.addEventListener("trix-file-accept", function (event) {
      event.preventDefault();
    });
  },
  mounted() {
    this.item?.speakers?.forEach((speaker) => {
      // Prepare ids for Dropdown
      this.speakers.push(speaker.id.toString());

      this.selected_speakers.push({
        id: speaker.id.toString(),
        first_name: speaker.first_name.toString(),
        last_name: speaker.last_name.toString(),
        expertise: speaker?.expertise,
        profile_img: speaker?.profile_img,
      });
    });
    this.$emit("speakersChanged", this.selected_speakers);

    //sponsors-list
    this.item?.sponsors?.forEach((sponsor) => {
      this.sponsors.push(sponsor.id.toString());
      this.selected_sponsors.push({
        id: sponsor.id.toString(),
        name: sponsor.name.toString(),
        label: sponsor.label.toString(),
        summary: sponsor.summary,
        logo: sponsor?.logo,
      });
    });
    this.$emit("sponsorsChanged", this.selected_sponsors);
  },
  computed: {
    publicURL() {
      return process.env.VUE_APP_BASE_URL + "/event/" + this.item.id;
    },
  },
  methods: {
    async getSpeakers() {
      let params = {
        filter: {
          ...(this.user
            ? { contractor_id: this.user.id }
            : { contractor_id: 0 }),
        },
      };
      try {
        await this.$store
          .dispatch("speakers/list", params)
          .then(
            () => (this.all_speakers = this.$store.getters["speakers/dropdown"])
          );
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong')+ '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    customFocus() {
      setTimeout(function () {
        document.getElementsByClassName("flatpickr-calendar open")[0].focus();
      }, 100);
    },
    setSelectedSpeakers() {
      const eventSpeakers = this.speakers;
      this.selected_speakers = [];
      eventSpeakers.forEach((tempSpeaker) => {
        //check if the selected speaker already belongs to the event and set the relevant values
        if (this.item?.speakers?.map((i) => i.id).indexOf(tempSpeaker) > -1) {
          this.item?.speakers.forEach((speaker) => {
            if (speaker.id === tempSpeaker) {
              this.selected_speakers.push({
                id: speaker.id.toString(),
                first_name: speaker.first_name.toString(),
                last_name: speaker.last_name.toString(),
                expertise: speaker?.expertise,
                profile_img: speaker?.profile_img,
              });
            }
          });
        } else {
          this.all_speakers.forEach((element) => {
            if (element.id === tempSpeaker) {
              this.selected_speakers.push(element);
            }
          });
        }
      });
      this.$emit("speakersChanged", this.selected_speakers);
    },
    //sponsors
    async getSponsors() {
      let params = {
        filter: {
          ...(this.user
            ? { contractor_id: this.user.id }
            : { contractor_id: 0 }),
        },
      };
      try {
        await this.$store
          .dispatch("sponsors/list", params)
          .then(
            () => (this.all_sponsors = this.$store.getters["sponsors/dropdown"])
          );
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    getSponsorLogo(logoUrl) {
      return process.env.VUE_APP_AWS_S3_URL + logoUrl;
    },
    setSelectedSponsors() {
      const eventSponsors = this.sponsors;
      this.selected_sponsors = [];
      eventSponsors.forEach((tempSponsor) => {
        //check if the selected speaker already belongs to the event and set the relevant values
        if (this.item?.sponsors?.map((i) => i.id).indexOf(tempSponsor) > -1) {
          this.item?.sponsors.forEach((sponsor) => {
            if (sponsor.id === tempSponsor) {
              this.selected_sponsors.push({
                id: sponsor.id.toString(),
                label: sponsor.label.toString(),
                name: sponsor.name.toString(),
                summary: sponsor.summary,
                logo: sponsor?.logo,
              });
            }
          });
        } else {
          this.all_sponsors.forEach((element) => {
            if (element.id === tempSponsor) {
              this.selected_sponsors.push(element);
            }
          });
        }
      });
      this.$emit("sponsorsChanged", this.selected_sponsors);
    },
  },
};
</script>
